
import { NavBar } from './navbar';
import {Body} from './body'
import {Footer} from './footer'
export const Main = () => {



    return(
        <>
            <Body/>
        </>
      )
}