
export const Files = ["assets/module/JuniorHighSchool/JHS-Checklist.pdf", 
"assets/module/JuniorHighSchool/Progress Report (JHS).pub", 
"assets/module/JuniorHighSchool/Grade 10/TLE/Quarter 1/TLE10_Q1-Q4_Week 1 - 37.pdf", 
"assets/module/JuniorHighSchool/Grade 10/AP/Quarter 1/Unit_1_Week_1_Araling_Panlipunan_10.pdf", 
"assets/module/JuniorHighSchool/Grade 10/AP/Quarter 1/Unit_1_Week_2_Araling_Panlipunan_10.pdf", 
"assets/module/JuniorHighSchool/Grade 10/AP/Quarter 1/Unit_1_Week_3_Araling_Panlipunan_10.pdf", 
"assets/module/JuniorHighSchool/Grade 10/AP/Quarter 1/Unit_1_Week_4_Araling_Panlipunan_10.pdf", 
"assets/module/JuniorHighSchool/Grade 10/AP/Quarter 1/Unit_1_Week_5_Araling_Panlipunan_10.pdf", 
"assets/module/JuniorHighSchool/Grade 10/AP/Quarter 2/Unit_2_Araling_Panlipunan_10.pdf", 
"assets/module/JuniorHighSchool/Grade 10/AP/Quarter 4/Unit_4_Week_1_Araling_Panlipunan_10.pdf", 
"assets/module/JuniorHighSchool/Grade 10/AP/Quarter 4/Unit_4_Week_2_Araling_Panlipunan_10.pdf", 
"assets/module/JuniorHighSchool/Grade 10/AP/Quarter 4/Unit_4_Week_3_Araling_Panlipunan_10.pdf", 
"assets/module/JuniorHighSchool/Grade 10/AP/Quarter 4/Unit_4_Week_4_Araling_Panlipunan_10.pdf", 
"assets/module/JuniorHighSchool/Grade 10/AP/Quarter 4/Unit_4_Week_5_Araling_Panlipunan_10.pdf", 
"assets/module/JuniorHighSchool/Grade 10/AP/Quarter 4/Unit_4_Week_6_Araling_Panlipunan_10.pdf", 
"assets/module/JuniorHighSchool/Grade 10/AP/Quarter 3/Unit_3_Week_1_Araling_Panlipunan_10.pdf", 
"assets/module/JuniorHighSchool/Grade 10/AP/Quarter 3/Unit_3_Week_2_Araling_Panlipunan_10.pdf", 
"assets/module/JuniorHighSchool/Grade 10/AP/Quarter 3/Unit_3_Week_3_Araling_Panlipunan_10.pdf", 
"assets/module/JuniorHighSchool/Grade 10/AP/Quarter 3/Unit_3_Week_4_Araling_Panlipunan_10.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Filipino/Quarter 2/Fil 10 Q2 W2.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Filipino/Quarter 2/Fil 10 Q2 W1.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Filipino/Quarter 2/Fil 10 Q2 W4.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Filipino/Quarter 2/Fil 10 Q2 W3.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Filipino/Quarter 2/Fil 10 Q2 W5.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Filipino/Quarter 2/Fil 10 Q2 W6.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Filipino/Quarter 2/Fil 10 Q2 W7.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Filipino/Quarter 1/Fil 10 Q1 W1.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Filipino/Quarter 1/Fil 10 Q1 W2.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Filipino/Quarter 1/Fil 10 Q1 W3.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Filipino/Quarter 1/Fil 10 Q1 W4.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Filipino/Quarter 1/Fil 10 Q1 W5.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Filipino/Quarter 1/Fil 10 Q1 W6.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Filipino/Quarter 1/Fil 10 Q1 W7.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Filipino/Quarter 1/Fil 10 Q1 W8.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Filipino/Quarter 3/Fil 10 Q3 W1.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Filipino/Quarter 3/Fil 10 Q3 W2.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Filipino/Quarter 3/Fil 10 Q3 W3.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Filipino/Quarter 3/Fil 10 Q3 W4.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Filipino/Quarter 3/Fil 10 Q3 W6.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Filipino/Quarter 3/Fil 10 Q3 W5.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Filipino/Quarter 3/Fil 10 Q3 W8.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Filipino/Quarter 3/Fil 10 Q3 W7.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Filipino/Quarter 4/Fil 10 Q4 W1.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Filipino/Quarter 4/Fil 10 Q4 W2.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Filipino/Quarter 4/Fil 10 Q4 W3.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Filipino/Quarter 4/Fil 10 Q4 W4.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Filipino/Quarter 4/Fil 10 Q4 W5.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Filipino/Quarter 4/Fil 10 Q4 W6.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Filipino/Quarter 4/Fil 10 Q4 W7.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Filipino/Quarter 4/Fil 10 Q4 W8.pdf", 
"assets/module/JuniorHighSchool/Grade 10/English/Quarter 1/English10_Q1_Week 1.pdf", 
"assets/module/JuniorHighSchool/Grade 10/English/Quarter 1/English10_Q1_Week 2.pdf", 
"assets/module/JuniorHighSchool/Grade 10/English/Quarter 1/English10_Q1_Week 3.pdf", 
"assets/module/JuniorHighSchool/Grade 10/English/Quarter 1/English10_Q1_Week 4.pdf", 
"assets/module/JuniorHighSchool/Grade 10/English/Quarter 1/English10_Q1_Week 5.pdf", 
"assets/module/JuniorHighSchool/Grade 10/English/Quarter 1/English10_Q1_Week 6.pdf", 
"assets/module/JuniorHighSchool/Grade 10/English/Quarter 3/Eng10_Q3_W1.pdf", 
"assets/module/JuniorHighSchool/Grade 10/English/Quarter 3/Eng10_Q3_W3.pdf", 
"assets/module/JuniorHighSchool/Grade 10/English/Quarter 3/Eng10_Q3_W2.pdf", 
"assets/module/JuniorHighSchool/Grade 10/English/Quarter 3/Eng10_Q3_W4.pdf", 
"assets/module/JuniorHighSchool/Grade 10/English/Quarter 3/Eng10_Q3_W5.pdf", 
"assets/module/JuniorHighSchool/Grade 10/English/Quarter 3/Eng10_Q3_W6.pdf", 
"assets/module/JuniorHighSchool/Grade 10/English/Quarter 3/Eng10_Q3_W8.pdf", 
"assets/module/JuniorHighSchool/Grade 10/English/Quarter 3/Eng10_Q3_W7.pdf", 
"assets/module/JuniorHighSchool/Grade 10/English/Quarter 2/Eng10_Q2_W2.pdf", 
"assets/module/JuniorHighSchool/Grade 10/English/Quarter 2/Eng10_Q2_W3.pdf", 
"assets/module/JuniorHighSchool/Grade 10/English/Quarter 2/Eng10_Q2_W4.pdf", 
"assets/module/JuniorHighSchool/Grade 10/English/Quarter 2/Eng10_Q2_W5.pdf", 
"assets/module/JuniorHighSchool/Grade 10/English/Quarter 2/Eng10_Q2_W6.pdf", 
"assets/module/JuniorHighSchool/Grade 10/English/Quarter 2/Eng10_Q2_W7.pdf", 
"assets/module/JuniorHighSchool/Grade 10/English/Quarter 4/Eng10_Q4_W1.pdf", 
"assets/module/JuniorHighSchool/Grade 10/English/Quarter 4/Eng10_Q4_W2.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Mathematics/QUARTER 2/M10 Q2M1-2.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Mathematics/QUARTER 2/M10 Q2M3.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Mathematics/QUARTER 2/M10 Q2M4.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Mathematics/QUARTER 1/M10 Q1M1.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Mathematics/QUARTER 1/M10 Q1M2.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Mathematics/QUARTER 1/M10 Q1M3.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Mathematics/QUARTER 1/M10 Q1M4.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Mathematics/QUARTER 3/M10 Q3M1-2.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Mathematics/QUARTER 3/M10 Q3M3.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Mathematics/QUARTER 3/M10 Q3M4-5.pdf", 
"assets/module/JuniorHighSchool/Grade 10/MAPEH/Quarter 1/Q1 MAPEH 10 WEEK 1 MUSIC.pdf", 
"assets/module/JuniorHighSchool/Grade 10/MAPEH/Quarter 1/Q1 MAPEH 10 WEEK 2 ARTS.pdf", 
"assets/module/JuniorHighSchool/Grade 10/MAPEH/Quarter 1/Q1 MAPEH 10 WEEK 3 PE.pdf", 
"assets/module/JuniorHighSchool/Grade 10/MAPEH/Quarter 1/Q1 MAPEH 10 WEEK 4 HEALTH.pdf", 
"assets/module/JuniorHighSchool/Grade 10/MAPEH/Quarter 1/Q1 MAPEH 10 WEEK 5 MUSIC.pdf", 
"assets/module/JuniorHighSchool/Grade 10/MAPEH/Quarter 1/Q1 MAPEH 10 WEEK 6 ARTS.pdf", 
"assets/module/JuniorHighSchool/Grade 10/MAPEH/Quarter 1/Q1 MAPEH 10 WEEK 7 PE.pdf", 
"assets/module/JuniorHighSchool/Grade 10/MAPEH/Quarter 1/Q1 MAPEH 10 WEEK 8 HEALTH.pdf", 
"assets/module/JuniorHighSchool/Grade 10/MAPEH/Quarter 2/Q2 MAPEH 10 WEEK 1 MUSIC.pdf", 
"assets/module/JuniorHighSchool/Grade 10/MAPEH/Quarter 2/Q2 MAPEH 10 WEEK 2 ARTS.pdf", 
"assets/module/JuniorHighSchool/Grade 10/MAPEH/Quarter 2/Q2 MAPEH 10 WEEK 3 P.E..pdf", 
"assets/module/JuniorHighSchool/Grade 10/MAPEH/Quarter 2/Q2 MAPEH 10 WEEK 4 HEALTH.pdf", 
"assets/module/JuniorHighSchool/Grade 10/MAPEH/Quarter 2/Q2 MAPEH 10 WEEK 5 MUSIC.pdf", 
"assets/module/JuniorHighSchool/Grade 10/MAPEH/Quarter 3/Q3 MAPEH 10 WEEK 1 MUSIC.pdf", 
"assets/module/JuniorHighSchool/Grade 10/MAPEH/Quarter 3/Q3 MAPEH 10 WEEK 2 ARTS.pdf", 
"assets/module/JuniorHighSchool/Grade 10/MAPEH/Quarter 3/Q3 MAPEH 10 WEEK 3 P.E..pdf", 
"assets/module/JuniorHighSchool/Grade 10/MAPEH/Quarter 3/Q3 MAPEH 10 WEEK 4 HEALTH.pdf", 
"assets/module/JuniorHighSchool/Grade 10/MAPEH/Quarter 3/Q3 MAPEH 10 WEEK 5 MUSIC.pdf", 
"assets/module/JuniorHighSchool/Grade 10/MAPEH/Quarter 3/Q3 MAPEH 10 WEEK 6 ARTS.pdf", 
"assets/module/JuniorHighSchool/Grade 10/MAPEH/Quarter 4/Q4 MAPEH 10 WEEK 1 MUSIC.pdf", 
"assets/module/JuniorHighSchool/Grade 10/MAPEH/Quarter 4/Q4 MAPEH 10 WEEK 2 ARTS.pdf", 
"assets/module/JuniorHighSchool/Grade 10/MAPEH/Quarter 4/Q4 MAPEH 10 WEEK 3 P.E..pdf", 
"assets/module/JuniorHighSchool/Grade 10/MAPEH/Quarter 4/Q4 MAPEH 10 WEEK 4 HEALTH.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Science/Sci10_Curriculum Map.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Science/Sci10_Curriculum TImeline.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Science/Quarter 1/Sci10_D.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Science/Quarter 1/Sci10_Q1_Mod1.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Science/Quarter 1/Sci10_Q1_Mod2.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Science/Quarter 1/Sci10_Q1_Mod3.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Science/Quarter 1/Sci10_Q1_Mod4.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Science/Quarter 1/Sci10_Q1_Mod5.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Science/Quarter 1/Sci10_Q1_Mod6.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Science/Quarter 2/Sci10_Q2_Mod1.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Science/Quarter 2/Sci10_Q2_Mod2.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Science/Quarter 2/Sci10_Q2_Mod3.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Science/Quarter 2/Sci10_Q2_Mod4.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Science/Quarter 2/Sci10_Q2_Mod5.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Science/Quarter 2/Sci10_Q2_Mod6.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Science/Quarter 3/Sci10_Q3_Mod1.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Science/Quarter 3/Sci10_Q3_Mod2.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Science/Quarter 3/Sci10_Q3_Mod3.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Science/Quarter 3/Sci10_Q3_Mod4.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Science/Quarter 3/Sci10_Q3_Mod5.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Science/Quarter 3/Sci10_Q3_Mod6.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Science/Quarter 4/Sci10_Q4_Mod1.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Science/Quarter 4/Sci10_Q4_Mod2.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Values/ValEd10_Curriculum TImeline.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Values/ValEd10_Curriculum Map.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Values/Quarter 1/Val.Ed10_Q1_Mod1.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Values/Quarter 1/Val.Ed10_Q1_Mod2.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Values/Quarter 1/Val.Ed10_Q1_Mod3.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Values/Quarter 1/Val.Ed10_Q1_Mod4.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Values/Quarter 1/Val.Ed10_Q1_Mod5.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Values/Quarter 2/Val.Ed10_Q2_Mod1.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Values/Quarter 2/Val.Ed10_Q2_Mod2.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Values/Quarter 2/Val.Ed10_Q2_Mod3.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Values/Quarter 2/Val.Ed10_Q2_Mod4.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Values/Quarter 4/ValEd10_Q4_Mod1.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Values/Quarter 4/ValEd10_Q4_Mod2.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Values/Quarter 3/Val.Ed10_Q3_Mod1.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Values/Quarter 3/Val.Ed10_Q3_Mod2.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Values/Quarter 3/Val.Ed10_Q3_Mod3.pdf", 
"assets/module/JuniorHighSchool/Grade 10/Values/Quarter 3/Val.Ed10_Q3_Mod4.pdf", 
"assets/module/JuniorHighSchool/Grade 7/TLE/Quarter 1/TLE7_Q1_Week 1 - 3 SY 2021-2022.pdf", 
"assets/module/JuniorHighSchool/Grade 7/TLE/Quarter 2/TLE7_Q2_Week 1 - 7.docx", 
"assets/module/JuniorHighSchool/Grade 7/TLE/Quarter 1/TLE7_Q1_Week 1 - 3 with Key Answers.pdf", 
"assets/module/JuniorHighSchool/Grade 7/TLE/Quarter 4/TLE7_Q4_Week 1.1 - materials of kitchen utensils and equipment.docx", 
"assets/module/JuniorHighSchool/Grade 7/TLE/Quarter 3/TLE7_Q3_Week 1 - 7.pdf", 
"assets/module/JuniorHighSchool/Grade 7/TLE/Quarter 4/TLE7_Q4_Week 1.2 - definition of terms.docx", 
"assets/module/JuniorHighSchool/Grade 7/TLE/Quarter 4/TLE7_Q4_Week 2.1.docx", 
"assets/module/JuniorHighSchool/Grade 7/TLE/Quarter 4/TLE7_Q4_Week 2.2 - Cooking Utensils List That Every Kitchen Needs.docx", 
"assets/module/JuniorHighSchool/Grade 7/TLE/Quarter 4/TLE7_Q4_Week 3.1.docx", 
"assets/module/JuniorHighSchool/Grade 7/TLE/Quarter 4/TLE7_Q4_Week 3.2 - cleaning and sanitizing.docx", 
"assets/module/JuniorHighSchool/Grade 7/TLE/Quarter 4/TLE7_Q4_Week 6.docx", 
"assets/module/JuniorHighSchool/Grade 7/TLE/Quarter 4/TLE7_Q4_Week 4 - Steps for Organizing Kitchen Cabinets.docx", 
"assets/module/JuniorHighSchool/Grade 7/TLE/Quarter 4/TLE7_Q4_Week 5.docx", 
"assets/module/JuniorHighSchool/Grade 7/TLE/Quarter 4/TLE7_Q4_Week 7.docx", 
"assets/module/JuniorHighSchool/Grade 7/AP/Quarter 2/Unit_2_Week_1_Araling_Panlipunan_7.pdf", 
"assets/module/JuniorHighSchool/Grade 7/AP/Quarter 2/Unit_2_Week_2_Araling_Panlipunan_7.pdf", 
"assets/module/JuniorHighSchool/Grade 7/AP/Quarter 2/Unit_2_Week_3_Araling_Panlipunan_7.docx.pdf", 
"assets/module/JuniorHighSchool/Grade 7/AP/Quarter 2/Unit_2_Week_4_Araling_Panlipunan_7.pdf", 
"assets/module/JuniorHighSchool/Grade 7/AP/Quarter 2/Unit_2_Week_5_Araling_Panlipunan_7.docx.pdf", 
"assets/module/JuniorHighSchool/Grade 7/AP/Quarter 2/Unit_2_Week_6_Araling_Panlipunan_7.pdf", 
"assets/module/JuniorHighSchool/Grade 7/AP/Quarter 1/Unit_1_Week_1_Araling_Panlipunan_7.pdf", 
"assets/module/JuniorHighSchool/Grade 7/AP/Quarter 1/Unit_1_Week_2_Araling_Panlipunan_7.pdf", 
"assets/module/JuniorHighSchool/Grade 7/AP/Quarter 1/Unit_1_Week_3_Araling_Panlipunan_7.pdf", 
"assets/module/JuniorHighSchool/Grade 7/AP/Quarter 1/Unit_1_Week_4_Araling_Panlipunan_7.pdf", 
"assets/module/JuniorHighSchool/Grade 7/AP/Quarter 1/Unit_1_Week_5_Araling_Panlipunan_7.pdf", 
"assets/module/JuniorHighSchool/Grade 7/AP/Quarter 3/Unit_3_Week_1_Araling_Panlipunan_7.pdf", 
"assets/module/JuniorHighSchool/Grade 7/AP/Quarter 3/Unit_3_Week_2_Araling_Panlipunan_7.pdf", 
"assets/module/JuniorHighSchool/Grade 7/AP/Quarter 3/Unit_3_Week_3_Araling_Panlipunan_7.pdf", 
"assets/module/JuniorHighSchool/Grade 7/AP/Quarter 3/Unit_3_Week_4_Araling_Panlipunan_7.pdf", 
"assets/module/JuniorHighSchool/Grade 7/AP/Quarter 3/Unit_3_Week_5_Araling_Panlipunan_7.pdf", 
"assets/module/JuniorHighSchool/Grade 7/AP/Quarter 3/Unit_3_Week_6_Araling_Panlipunan_7.pdf", 
"assets/module/JuniorHighSchool/Grade 7/AP/Quarter 3/Unit_3_Week_7_Araling_Panlipunan_7.pdf", 
"assets/module/JuniorHighSchool/Grade 7/AP/Quarter 4/Unit_4_Week_1_Araling_Panlipunan_7.pdf", 
"assets/module/JuniorHighSchool/Grade 7/AP/Quarter 4/Unit_4_Week_2_Araling_Panlipunan_7.pdf", 
"assets/module/JuniorHighSchool/Grade 7/AP/Quarter 4/Unit_4_Week_3_Araling_Panlipunan_7.pdf", 
"assets/module/JuniorHighSchool/Grade 7/AP/Quarter 4/Unit_4_Week_4_Araling_Panlipunan_7.pdf", 
"assets/module/JuniorHighSchool/Grade 7/AP/Quarter 4/Unit_4_Week_5_Araling_Panlipunan_7.pdf", 
"assets/module/JuniorHighSchool/Grade 7/AP/Quarter 4/Unit_4_Week_6_Araling_Panlipunan_7.pdf", 
"assets/module/JuniorHighSchool/Grade 7/English/Quarter 1/English7_Q1_Week 1.pdf", 
"assets/module/JuniorHighSchool/Grade 7/English/Quarter 1/English7_Q1_Week 2.pdf", 
"assets/module/JuniorHighSchool/Grade 7/English/Quarter 1/English7_Q1_Week 3.pdf", 
"assets/module/JuniorHighSchool/Grade 7/English/Quarter 1/English7_Q1_Week 4.pdf", 
"assets/module/JuniorHighSchool/Grade 7/English/Quarter 1/English7_Q1_Week 5.pdf", 
"assets/module/JuniorHighSchool/Grade 7/English/Quarter 1/English7_Q1_Week 6.pdf", 
"assets/module/JuniorHighSchool/Grade 7/English/Quarter 3/English7_Q3_W1.pdf", 
"assets/module/JuniorHighSchool/Grade 7/English/Quarter 3/English7_Q3_W2.pdf", 
"assets/module/JuniorHighSchool/Grade 7/English/Quarter 3/English7_Q3_W3.pdf", 
"assets/module/JuniorHighSchool/Grade 7/English/Quarter 3/English7_Q3_W4.pdf", 
"assets/module/JuniorHighSchool/Grade 7/English/Quarter 3/English7_Q3_W5.pdf", 
"assets/module/JuniorHighSchool/Grade 7/English/Quarter 3/English7_Q3_W6.pdf", 
"assets/module/JuniorHighSchool/Grade 7/English/Quarter 2/Eng7_Q2_W1.pdf", 
"assets/module/JuniorHighSchool/Grade 7/English/Quarter 2/Eng7_Q2_W2.pdf", 
"assets/module/JuniorHighSchool/Grade 7/English/Quarter 2/Eng7_Q2_W3.pdf", 
"assets/module/JuniorHighSchool/Grade 7/English/Quarter 2/Eng7_Q2_W4.pdf", 
"assets/module/JuniorHighSchool/Grade 7/English/Quarter 2/Eng7_Q2_W5.pdf", 
"assets/module/JuniorHighSchool/Grade 7/English/Quarter 2/Eng7_Q2_W6.pdf", 
"assets/module/JuniorHighSchool/Grade 7/English/Quarter 2/Eng7_Q2_W7.pdf", 
"assets/module/JuniorHighSchool/Grade 7/English/Quarter 4/English7_Q4_W1.pdf", 
"assets/module/JuniorHighSchool/Grade 7/English/Quarter 4/English7_Q4_W2.pdf", 
"assets/module/JuniorHighSchool/Grade 7/English/Quarter 4/English7_Q4_W3.pdf", 
"assets/module/JuniorHighSchool/Grade 7/English/Quarter 4/English7_Q4_W6.pdf", 
"assets/module/JuniorHighSchool/Grade 7/English/Quarter 4/English7_Q4_W4.pdf", 
"assets/module/JuniorHighSchool/Grade 7/English/Quarter 4/English7_Q4_W5.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Mathematics/QUARTER 2/M7 Q2 M3.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Mathematics/QUARTER 2/M7 Q2 M4.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Mathematics/QUARTER 2/M7 Q2M1-2.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Mathematics/QUARTER 1/M7 Q1W1.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Mathematics/QUARTER 1/M7 Q1W2.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Mathematics/QUARTER 1/M7 Q1W3.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Mathematics/QUARTER 1/M7 Q1W4.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Mathematics/QUARTER 1/M7 Q1WM5.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Mathematics/QUARTER 1/M7 Q1WM6.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Mathematics/QUARTER 3/M7 Q3M1-2.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Mathematics/QUARTER 3/M7 Q3M3-4.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Mathematics/QUARTER 3/M7 Q3M5-6.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Science/Sci7_Curriculum Map.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Science/Sci7_Curriculum TImeline.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Science/Sci7_D.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Science/Quarter 2/Sci7_Q2_Mod1.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Science/Quarter 2/Sci7_Q2_Mod2.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Science/Quarter 2/Sci7_Q2_Mod3.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Science/Quarter 2/Sci7_Q2_Mod4.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Science/Quarter 2/Sci7_Q2_Mod5.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Science/Quarter 2/Sci7_Q2_Mod6.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Science/Quarter 1/Sci7_Q1_Mod1.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Science/Quarter 1/Sci7_Q1_Mod2.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Science/Quarter 1/Sci7_Q1_Mod3.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Science/Quarter 1/Sci7_Q1_Mod4.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Science/Quarter 1/Sci7_Q1_Mod5.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Science/Quarter 1/Sci7_Q1_Mod6.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Science/Quarter 4/Sci7_Q4_Mod1.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Science/Quarter 4/Sci7_Q4_Mod2.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Science/Quarter 3/Sci7_Q3_Mod1.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Science/Quarter 3/Sci7_Q3_Mod2.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Science/Quarter 3/Sci7_Q3_Mod3.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Science/Quarter 3/Sci7_Q3_Mod4.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Science/Quarter 3/Sci7_Q3_Mod5.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Science/Quarter 3/Sci7_Q3_Mod6.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Values/Quarter 1/Q1_W1_ValuesEducation7.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Values/Quarter 1/Q1_W2_ValuesEducation7.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Values/Quarter 1/Q1_W3_ValuesEducation7.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Values/Quarter 1/Q1_W4_ValuesEducation7.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Values/Quarter 1/Q1_W5_ValuesEducation7.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Values/Quarter 1/Q1_W6_ValuesEducation7.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Values/Quarter 1/Q1_W7_ValuesEducation7.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Values/Quarter 2/Q2_W1_ValEd7.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Values/Quarter 2/Q2_W2_ValEd7.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Values/Quarter 2/Q2_W3_ValEd7.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Values/Quarter 2/Q2_W4_ValEd7.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Values/Quarter 2/Q2_W5-6_ValEd7.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Values/Quarter 3/Q3_W1_ValEd7 (1).pdf", 
"assets/module/JuniorHighSchool/Grade 7/Values/Quarter 3/Q3_W2_ValEd7.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Values/Quarter 3/Q3_W3_ValEd7.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Values/Quarter 3/Q3_W4_ValEd7.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Values/Quarter 3/Q3_W5_ValEd7.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Values/Quarter 3/Q3_W6_ValEd7.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Values/Quarter 3/Q3_W7_ValEd7.pdf", 
"assets/module/JuniorHighSchool/Grade 7/MAPEH/Quarter 1/Q1 MAPEH 7 WEEK 1 MUSIC.pdf", 
"assets/module/JuniorHighSchool/Grade 7/MAPEH/Quarter 1/Q1 MAPEH 7 WEEK 2 ARTS.pdf", 
"assets/module/JuniorHighSchool/Grade 7/MAPEH/Quarter 1/Q1 MAPEH 7 WEEK 3 PE.pdf", 
"assets/module/JuniorHighSchool/Grade 7/MAPEH/Quarter 1/Q1 MAPEH 7 WEEK 4 HEALTH.pdf", 
"assets/module/JuniorHighSchool/Grade 7/MAPEH/Quarter 1/Q1 MAPEH 7 WEEK 5 MUSIC.pdf", 
"assets/module/JuniorHighSchool/Grade 7/MAPEH/Quarter 1/Q1 MAPEH 7 WEEK 6 ARTS.pdf", 
"assets/module/JuniorHighSchool/Grade 7/MAPEH/Quarter 1/Q1 MAPEH 7 WEEK 7 PE.pdf", 
"assets/module/JuniorHighSchool/Grade 7/MAPEH/Quarter 1/Q1 MAPEH 7 WEEK 8 HEALTH.pdf", 
"assets/module/JuniorHighSchool/Grade 7/MAPEH/Quarter 3/Q3 MAPEH 7 WEEK 1 MUSIC.pdf", 
"assets/module/JuniorHighSchool/Grade 7/MAPEH/Quarter 3/Q3 MAPEH 7 WEEK 2 ARTS.pdf", 
"assets/module/JuniorHighSchool/Grade 7/MAPEH/Quarter 3/Q3 MAPEH 7 WEEK 3 P.E..pdf", 
"assets/module/JuniorHighSchool/Grade 7/MAPEH/Quarter 3/Q3 MAPEH 7 WEEK 4 HEALTH.pdf", 
"assets/module/JuniorHighSchool/Grade 7/MAPEH/Quarter 3/Q3 MAPEH 7 WEEK 5 MUSIC.pdf", 
"assets/module/JuniorHighSchool/Grade 7/MAPEH/Quarter 3/Q3 MAPEH 7 WEEK 6 ARTS.pdf", 
"assets/module/JuniorHighSchool/Grade 7/MAPEH/Quarter 2/Q2 MAPEH 7 WEEK 1 MUSIC.pdf", 
"assets/module/JuniorHighSchool/Grade 7/MAPEH/Quarter 2/Q2 MAPEH 7 WEEK 2 ARTS.pdf", 
"assets/module/JuniorHighSchool/Grade 7/MAPEH/Quarter 2/Q2 MAPEH 7 WEEK 3 P.E..pdf", 
"assets/module/JuniorHighSchool/Grade 7/MAPEH/Quarter 2/Q2 MAPEH 7 WEEK 4 HEALTH.pdf", 
"assets/module/JuniorHighSchool/Grade 7/MAPEH/Quarter 2/Q2 MAPEH 7 WEEK 5 MUSIC.pdf", 
"assets/module/JuniorHighSchool/Grade 7/MAPEH/Quarter 4/Q4 MAPEH 7 WEEK 2 ARTS.pdf", 
"assets/module/JuniorHighSchool/Grade 7/MAPEH/Quarter 4/Q4 MAPEH 7 WEEK 3 PE.pdf", 
"assets/module/JuniorHighSchool/Grade 7/MAPEH/Quarter 4/Q4 MAPEH 7 WEEK 4 HEALTH.pdf", 
"assets/module/JuniorHighSchool/Grade 7/MAPEH/Quarter 4/Q4 MAPEH 7 WEEK 1 MUSIC.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Filipino/Quarter 2/Q2_W1-2_Filipino7.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Filipino/Quarter 2/Q2_W3-4-Filipino7.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Filipino/Quarter 2/Q2_W7-8_Filipino7.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Filipino/Quarter 2/Q2_W5-6_Filipino7.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Filipino/Quarter 4/Q4_W1_Filipino7.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Filipino/Quarter 4/Q4_W2_Filipino7.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Filipino/Quarter 4/Q4_W3_Filipino7.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Filipino/Quarter 3/Q3_W3_Filipino7.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Filipino/Quarter 3/Q3_W2_Filipino7.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Filipino/Quarter 3/Q3_W4_Filipino7.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Filipino/Quarter 3/Q3_W5_Filipino7.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Filipino/Quarter 3/Q3_W6_Filipino7.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Filipino/Quarter 3/Q3_W7_Filipino7.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Filipino/Quarter 3/Q3_W1_Filipino7.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Filipino/Quarter 1/Q1_W1_Filipino7.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Filipino/Quarter 1/Q1_W2_Filipino7.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Filipino/Quarter 1/Q1_W3_Filipino7.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Filipino/Quarter 1/Q1_W4_Filipino7.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Filipino/Quarter 1/Q1_W5_Filipino7.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Filipino/Quarter 1/Q1_W6_Filipino7.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Filipino/Quarter 1/Q1_W7_Filipino7.pdf", 
"assets/module/JuniorHighSchool/Grade 7/Filipino/Quarter 1/Q1_W8_Filipino8.pdf", 
"assets/module/JuniorHighSchool/Grade 9/AP/Quarter 1/Unit_1_Week_1_Araling_Panlipunan_9.pdf", 
"assets/module/JuniorHighSchool/Grade 9/AP/Quarter 1/Unit_1_Week_2_Araling_Panlipunan_9.pdf", 
"assets/module/JuniorHighSchool/Grade 9/AP/Quarter 1/Unit_1_Week_3_Araling_Panlipunan_9.pdf", 
"assets/module/JuniorHighSchool/Grade 9/AP/Quarter 1/Unit_1_Week_4_Araling_Panlipunan_9.pdf", 
"assets/module/JuniorHighSchool/Grade 9/AP/Quarter 2/Unit_1_Week_5_Araling_Panlipunan_9.pdf", 
"assets/module/JuniorHighSchool/Grade 9/AP/Quarter 2/Unit_1_Week_6_Araling_Panlipunan_9.pdf", 
"assets/module/JuniorHighSchool/Grade 9/AP/Quarter 2/Unit_2_Week_1_Araling_Panlipunan_9.pdf", 
"assets/module/JuniorHighSchool/Grade 9/AP/Quarter 3/Unit_2_Week_2_Araling_Panlipunan_9.pdf", 
"assets/module/JuniorHighSchool/Grade 9/AP/Quarter 3/Unit_2_Week_3_Araling_Panlipunan_9.pdf", 
"assets/module/JuniorHighSchool/Grade 9/AP/Quarter 4/Unit_2_Week_4_Araling_Panlipunan_9.pdf", 
"assets/module/JuniorHighSchool/Grade 9/AP/Quarter 4/Unit_2_Week_5_Araling_Panlipunan_9.pdf", 
"assets/module/JuniorHighSchool/Grade 9/TLE/Quarter 1/TLE9_Q1_Week 1 - 10.pdf", 
"assets/module/JuniorHighSchool/Grade 9/TLE/Quarter 2/TLE9_Q2_Week 12 - 18.pdf", 
"assets/module/JuniorHighSchool/Grade 9/TLE/Quarter 3/TLE9_Q3_Week 1.pdf", 
"assets/module/JuniorHighSchool/Grade 9/TLE/Quarter 4/TLE9_Q4.pdf", 
"assets/module/JuniorHighSchool/Grade 9/TLE/TLE9_Q4_Final-Requirement.png", 
"assets/module/JuniorHighSchool/Grade 9/Values/Quarter 1/Unit_1_Values_Education_9.pdf", 
"assets/module/JuniorHighSchool/Grade 9/English/Quarter 2/Eng9_Q2_W1.pdf", 
"assets/module/JuniorHighSchool/Grade 9/English/Quarter 2/Eng9_Q2_W2.pdf", 
"assets/module/JuniorHighSchool/Grade 9/English/Quarter 2/Eng9_Q2_W3.pdf", 
"assets/module/JuniorHighSchool/Grade 9/English/Quarter 2/Eng9_Q2_W4.pdf", 
"assets/module/JuniorHighSchool/Grade 9/English/Quarter 2/Eng9_Q2_W5.pdf", 
"assets/module/JuniorHighSchool/Grade 9/English/Quarter 2/Eng9_Q2_W6.pdf", 
"assets/module/JuniorHighSchool/Grade 9/English/Quarter 1/English9_Q1_Week 1 - 7.pdf", 
"assets/module/JuniorHighSchool/Grade 9/English/Quarter 3/English9_Q3_W1.pdf", 
"assets/module/JuniorHighSchool/Grade 9/English/Quarter 3/English9_Q3_W2.pdf", 
"assets/module/JuniorHighSchool/Grade 9/English/Quarter 3/English9_Q3_W3.pdf", 
"assets/module/JuniorHighSchool/Grade 9/English/Quarter 3/English9_Q3_W4.pdf", 
"assets/module/JuniorHighSchool/Grade 9/English/Quarter 3/English9_Q3_W5.pdf", 
"assets/module/JuniorHighSchool/Grade 9/English/Quarter 3/English9_Q3_W6.pdf", 
"assets/module/JuniorHighSchool/Grade 9/English/Quarter 4/English9_Q4_W1.pdf", 
"assets/module/JuniorHighSchool/Grade 9/English/Quarter 4/English9_Q4_W2.pdf", 
"assets/module/JuniorHighSchool/Grade 9/English/Quarter 4/English9_Q4_W3.pdf", 
"assets/module/JuniorHighSchool/Grade 9/English/Quarter 4/English9_Q4_W4.pdf", 
"assets/module/JuniorHighSchool/Grade 9/English/Quarter 4/English9_Q4_W5.pdf", 
"assets/module/JuniorHighSchool/Grade 9/Filipino/Quarter 1/Fil 9 Q1 W1.pdf", 
"assets/module/JuniorHighSchool/Grade 9/Filipino/Quarter 1/Fil 9 Q1 W2.pdf", 
"assets/module/JuniorHighSchool/Grade 9/Filipino/Quarter 1/Fil 9 Q1 W3.pdf", 
"assets/module/JuniorHighSchool/Grade 9/Filipino/Quarter 1/Fil 9 Q1 W4.pdf", 
"assets/module/JuniorHighSchool/Grade 9/Filipino/Quarter 1/Fil 9 Q1 W5-6.pdf", 
"assets/module/JuniorHighSchool/Grade 9/Filipino/Quarter 1/Fil 9 Q1 W7.pdf", 
"assets/module/JuniorHighSchool/Grade 9/Filipino/Quarter 1/Fil 9 Q1 W8.pdf", 
"assets/module/JuniorHighSchool/Grade 9/Filipino/Quarter 2/Fil 9 Q2 W1.pdf", 
"assets/module/JuniorHighSchool/Grade 9/Filipino/Quarter 2/Fil 9 Q2 W2.pdf", 
"assets/module/JuniorHighSchool/Grade 9/Filipino/Quarter 2/Fil 9 Q2 W3.pdf", 
"assets/module/JuniorHighSchool/Grade 9/Filipino/Quarter 2/Fil 9 Q2 W4.pdf", 
"assets/module/JuniorHighSchool/Grade 9/Filipino/Quarter 2/Fil 9 Q2 W5.pdf", 
"assets/module/JuniorHighSchool/Grade 9/Filipino/Quarter 2/Fil 9 Q2 W6.pdf", 
"assets/module/JuniorHighSchool/Grade 9/Filipino/Quarter 2/Fil 9 Q2 W7.pdf", 
"assets/module/JuniorHighSchool/Grade 9/Filipino/Quarter 3/Fil 9 Q3 W1.pdf", 
"assets/module/JuniorHighSchool/Grade 9/Filipino/Quarter 3/Fil 9 Q3 W2.pdf", 
"assets/module/JuniorHighSchool/Grade 9/Filipino/Quarter 3/Fil 9 Q3 W3.pdf", 
"assets/module/JuniorHighSchool/Grade 9/Filipino/Quarter 3/Fil 9 Q3 W4.pdf", 
"assets/module/JuniorHighSchool/Grade 9/Filipino/Quarter 3/Fil 9 Q3 W5.pdf", 
"assets/module/JuniorHighSchool/Grade 9/Filipino/Quarter 3/Fil 9 Q3 W6.pdf", 
"assets/module/JuniorHighSchool/Grade 9/Filipino/Quarter 3/Fil 9 Q3 W7.pdf", 
"assets/module/JuniorHighSchool/Grade 9/Filipino/Quarter 3/Fil 9 Q3 W8.pdf", 
"assets/module/JuniorHighSchool/Grade 9/Filipino/Quarter 4/Fil 9 Q3 W4.pdf", 
"assets/module/JuniorHighSchool/Grade 9/Filipino/Quarter 4/Fil 9 Q4 W1.pdf", 
"assets/module/JuniorHighSchool/Grade 9/Filipino/Quarter 4/Fil 9 Q4 W2.pdf", 
"assets/module/JuniorHighSchool/Grade 9/Filipino/Quarter 4/Fil 9 Q4 W3.pdf", 
"assets/module/JuniorHighSchool/Grade 9/Filipino/Quarter 4/Fil 9 Q4 W5.pdf", 
"assets/module/JuniorHighSchool/Grade 9/Filipino/Quarter 4/Fil 9 Q4 W6.pdf", 
"assets/module/JuniorHighSchool/Grade 9/Filipino/Quarter 4/Fil 9 Q4 W7.pdf", 
"assets/module/JuniorHighSchool/Grade 9/Filipino/Quarter 4/Fil 9 Q4 W8.pdf", 
"assets/module/JuniorHighSchool/Grade 9/MAPEH/Quarter 4/Q4 MAPEH 9 WEEK 1 MUSIC.pdf", 
"assets/module/JuniorHighSchool/Grade 9/MAPEH/Quarter 4/Q4 MAPEH 9 WEEK 2 ARTS.pdf", 
"assets/module/JuniorHighSchool/Grade 9/MAPEH/Quarter 4/Q4 MAPEH 9 WEEK 3 P.E..pdf", 
"assets/module/JuniorHighSchool/Grade 9/MAPEH/Quarter 4/Q4 MAPEH 9 WEEK 4 HEALTH.pdf", 
"assets/module/JuniorHighSchool/Grade 9/MAPEH/Quarter 2/Q2 MAPEH 9 WEEK 1 MUSIC.pdf", 
"assets/module/JuniorHighSchool/Grade 9/MAPEH/Quarter 2/Q2 MAPEH 9 WEEK 2 ARTS.pdf", 
"assets/module/JuniorHighSchool/Grade 9/MAPEH/Quarter 2/Q2 MAPEH 9 WEEK 3 P.E..pdf", 
"assets/module/JuniorHighSchool/Grade 9/MAPEH/Quarter 2/Q2 MAPEH 9 WEEK 4 HEALTH.pdf", 
"assets/module/JuniorHighSchool/Grade 9/MAPEH/Quarter 2/Q2 MAPEH 9 WEEK 5 MUSIC.pdf", 
"assets/module/JuniorHighSchool/Grade 9/MAPEH/Quarter 1/Q1 MAPEH 9 WEEK 1 MUSIC.pdf", 
"assets/module/JuniorHighSchool/Grade 9/MAPEH/Quarter 1/Q1 MAPEH 9 WEEK 2 ARTS.pdf", 
"assets/module/JuniorHighSchool/Grade 9/MAPEH/Quarter 1/Q1 MAPEH 9 WEEK 3 PE.pdf", 
"assets/module/JuniorHighSchool/Grade 9/MAPEH/Quarter 1/Q1 MAPEH 9 WEEK 4 HEALTH.pdf", 
"assets/module/JuniorHighSchool/Grade 9/MAPEH/Quarter 1/Q1 MAPEH 9 WEEK 7 PE.pdf", 
"assets/module/JuniorHighSchool/Grade 9/MAPEH/Quarter 1/Q1 MAPEH 9 WEEK 6 ARTS.pdf", 
"assets/module/JuniorHighSchool/Grade 9/MAPEH/Quarter 1/Q1 MAPEH 9 WEEK 5 MUSIC.pdf", 
"assets/module/JuniorHighSchool/Grade 9/MAPEH/Quarter 1/Q1 MAPEH 9 WEEK 8 HEALTH.pdf", 
"assets/module/JuniorHighSchool/Grade 9/MAPEH/Quarter 3/Q3 MAPEH 9 WEEK 1 MUSIC.pdf", 
"assets/module/JuniorHighSchool/Grade 9/MAPEH/Quarter 3/Q3 MAPEH 9 WEEK 2 ARTS.pdf", 
"assets/module/JuniorHighSchool/Grade 9/MAPEH/Quarter 3/Q3 MAPEH 9 WEEK 3 P.E..pdf", 
"assets/module/JuniorHighSchool/Grade 9/MAPEH/Quarter 3/Q3 MAPEH 9 WEEK 4 HEALTH.pdf", 
"assets/module/JuniorHighSchool/Grade 9/MAPEH/Quarter 3/Q3 MAPEH 9 WEEK 5 MUSIC.pdf", 
"assets/module/JuniorHighSchool/Grade 9/MAPEH/Quarter 3/Q3 MAPEH 9 WEEK 6 ARTS.pdf", 
"assets/module/JuniorHighSchool/Grade 9/Science/Sci9_Curriculum Map.pdf", 
"assets/module/JuniorHighSchool/Grade 9/Science/Sci9_Curriculum TImeline.pdf", 
"assets/module/JuniorHighSchool/Grade 9/Science/Quarter 1/Sci9_D.pdf", 
"assets/module/JuniorHighSchool/Grade 9/Science/Quarter 1/Sci9_Q1_Mod1.pdf", 
"assets/module/JuniorHighSchool/Grade 9/Science/Quarter 1/Sci9_Q1_Mod2.pdf", 
"assets/module/JuniorHighSchool/Grade 9/Science/Quarter 1/Sci9_Q1_Mod3.pdf", 
"assets/module/JuniorHighSchool/Grade 9/Science/Quarter 1/Sci9_Q1_Mod4.pdf", 
"assets/module/JuniorHighSchool/Grade 9/Science/Quarter 1/Sci9_Q1_Mod5.pdf", 
"assets/module/JuniorHighSchool/Grade 9/Science/Quarter 1/Sci9_Q1_Mod6.pdf", 
"assets/module/JuniorHighSchool/Grade 9/Science/Quarter 3/Sci9_Q3_Mod1.pdf", 
"assets/module/JuniorHighSchool/Grade 9/Science/Quarter 3/Sci9_Q3_Mod2.pdf", 
"assets/module/JuniorHighSchool/Grade 9/Science/Quarter 3/Sci9_Q3_Mod3.pdf", 
"assets/module/JuniorHighSchool/Grade 9/Science/Quarter 3/Sci9_Q3_Mod4.pdf", 
"assets/module/JuniorHighSchool/Grade 9/Science/Quarter 3/Sci9_Q3_Mod5.pdf", 
"assets/module/JuniorHighSchool/Grade 9/Science/Quarter 3/Sci9_Q3_Mod6.pdf", 
"assets/module/JuniorHighSchool/Grade 9/Science/Quarter 4/Sci9_Q4_Mod1.pdf", 
"assets/module/JuniorHighSchool/Grade 9/Science/Quarter 4/Sci9_Q4_Mod2.pdf", 
"assets/module/JuniorHighSchool/Grade 9/Science/Quarter 2/Sci9_Q2_Mod1.pdf", 
"assets/module/JuniorHighSchool/Grade 9/Science/Quarter 2/Sci9_Q2_Mod2.pdf", 
"assets/module/JuniorHighSchool/Grade 9/Science/Quarter 2/Sci9_Q2_Mod3.pdf", 
"assets/module/JuniorHighSchool/Grade 9/Science/Quarter 2/Sci9_Q2_Mod4.pdf", 
"assets/module/JuniorHighSchool/Grade 9/Science/Quarter 2/Sci9_Q2_Mod6.pdf", 
"assets/module/JuniorHighSchool/Grade 9/Science/Quarter 2/Sci9_Q2_Mod5.pdf", 
"assets/module/JuniorHighSchool/Grade 9/Mathematics/QUARTER 3/M9 Q3M1-2.pdf", 
"assets/module/JuniorHighSchool/Grade 9/Mathematics/QUARTER 3/M9 Q3M3-4.pdf", 
"assets/module/JuniorHighSchool/Grade 9/Mathematics/QUARTER 3/M9 Q3M5-6.pdf", 
"assets/module/JuniorHighSchool/Grade 9/Mathematics/QUARTER 2/M9 Q2M1-2.pdf", 
"assets/module/JuniorHighSchool/Grade 9/Mathematics/QUARTER 2/M9 Q2M3.pdf", 
"assets/module/JuniorHighSchool/Grade 9/Mathematics/QUARTER 2/M9 Q2M4-5.pdf", 
"assets/module/JuniorHighSchool/Grade 9/Mathematics/QUARTER 2/M9 Q2M6.pdf", 
"assets/module/JuniorHighSchool/Grade 9/Mathematics/QUARTER 1/M9 Q1M1.pdf", 
"assets/module/JuniorHighSchool/Grade 9/Mathematics/QUARTER 1/M9 Q1M2.pdf", 
"assets/module/JuniorHighSchool/Grade 9/Mathematics/QUARTER 1/M9 Q1M3-4.pdf", 
"assets/module/JuniorHighSchool/Grade 9/Mathematics/QUARTER 1/M9 Q1M5.pdf", 
"assets/module/JuniorHighSchool/Grade 9/Mathematics/QUARTER 1/M9 Q1M6.pdf", 
"assets/module/JuniorHighSchool/Grade 9/Mathematics/QUARTER 1/M9 Q1M7.pdf", 
"assets/module/JuniorHighSchool/Grade 8/TLE/Quarter 1/TLE8_Q1_Week 1 - 3 S.Y. 2021-2022.pdf", 
"assets/module/JuniorHighSchool/Grade 8/TLE/Quarter 1/TLE8_Q1_Week 1 - 3 with key answers.pdf", 
"assets/module/JuniorHighSchool/Grade 8/TLE/Quarter 2/TLE8_Q2_Week 1 - 7.docx", 
"assets/module/JuniorHighSchool/Grade 8/TLE/Quarter 3/TLE8_Q3_Week 1 - 7.pdf", 
"assets/module/JuniorHighSchool/Grade 8/TLE/Quarter 4/TLE8_Q4_Week 1.2 - definition of terms.docx", 
"assets/module/JuniorHighSchool/Grade 8/TLE/Quarter 4/TLE8_Q4_Week 1.1 - materials of kitchen utensils and equipment.docx", 
"assets/module/JuniorHighSchool/Grade 8/TLE/Quarter 4/TLE8_Q4_Week 2.2 - Cooking Utensils List That Every Kitchen Needs.docx", 
"assets/module/JuniorHighSchool/Grade 8/TLE/Quarter 4/TLE8_Q4_Week 2.1.docx", 
"assets/module/JuniorHighSchool/Grade 8/TLE/Quarter 4/TLE8_Q4_Week 3.1.docx", 
"assets/module/JuniorHighSchool/Grade 8/TLE/Quarter 4/TLE8_Q4_Week 3.2 - cleaning and sanitizing.docx", 
"assets/module/JuniorHighSchool/Grade 8/TLE/Quarter 4/TLE8_Q4_Week 4 - Steps for Organizing Kitchen Cabinets.docx", 
"assets/module/JuniorHighSchool/Grade 8/TLE/Quarter 4/TLE8_Q4_Week 5.docx", 
"assets/module/JuniorHighSchool/Grade 8/TLE/Quarter 4/TLE8_Q4_Week 6.docx", 
"assets/module/JuniorHighSchool/Grade 8/TLE/Quarter 4/TLE8_Q4_Week 7.docx", 
"assets/module/JuniorHighSchool/Grade 8/AP/Quarter 1/Unit_1_Week_1_Araling_Panlipunan_8.pdf", 
"assets/module/JuniorHighSchool/Grade 8/AP/Quarter 1/Unit_1_Week_2_Araling_Panlipunan_8.pdf", 
"assets/module/JuniorHighSchool/Grade 8/AP/Quarter 1/Unit_1_Week_3_Araling_Panlipunan_8.pdf", 
"assets/module/JuniorHighSchool/Grade 8/AP/Quarter 1/Unit_1_Week_4_Araling_Panlipunan_8.pdf", 
"assets/module/JuniorHighSchool/Grade 8/AP/Quarter 1/Unit_1_Week_5_Araling_Panlipunan_8.pdf", 
"assets/module/JuniorHighSchool/Grade 8/AP/Quarter 1/Unit_1_Week_6_Araling_Panlipunan_8.pdf", 
"assets/module/JuniorHighSchool/Grade 8/AP/Quarter 1/Unit_1_Week_7_Araling_Panlipunan_8.pdf", 
"assets/module/JuniorHighSchool/Grade 8/AP/Quarter 2/Unit_2_Week_1_Araling_Panlipunan_8.pdf", 
"assets/module/JuniorHighSchool/Grade 8/AP/Quarter 2/Unit_2_Week_2_Araling_Panlipunan_8.docx.pdf", 
"assets/module/JuniorHighSchool/Grade 8/AP/Quarter 2/Unit_2_Week_3_Araling_Panlipunan_8.pdf", 
"assets/module/JuniorHighSchool/Grade 8/AP/Quarter 2/Unit_2_Week_4_Araling_Panlipunan_8.pdf", 
"assets/module/JuniorHighSchool/Grade 8/AP/Quarter 2/Unit_2_Week_5_Araling_Panlipunan_8.pdf", 
"assets/module/JuniorHighSchool/Grade 8/AP/Quarter 4/Unit_4_Week_1_Araling_Panlipunan_8.pdf", 
"assets/module/JuniorHighSchool/Grade 8/AP/Quarter 4/Unit_4_Week_2_Araling_Panlipunan_8.pdf", 
"assets/module/JuniorHighSchool/Grade 8/AP/Quarter 4/Unit_4_Week_3_Araling_Panlipunan_8.pdf", 
"assets/module/JuniorHighSchool/Grade 8/AP/Quarter 4/Unit_4_Week_4_Araling_Panlipunan_8.pdf", 
"assets/module/JuniorHighSchool/Grade 8/AP/Quarter 4/Unit_4_Week_5_Araling_Panlipunan_8.pdf", 
"assets/module/JuniorHighSchool/Grade 8/AP/Quarter 3/Unit_3_Week_1_Araling_Panlipunan_8.pdf", 
"assets/module/JuniorHighSchool/Grade 8/AP/Quarter 3/Unit_3_Week_2_Araling_Panlipunan_8.pdf", 
"assets/module/JuniorHighSchool/Grade 8/AP/Quarter 3/Unit_3_Week_4_Araling_Panlipunan_8.pdf", 
"assets/module/JuniorHighSchool/Grade 8/AP/Quarter 3/Unit_3_Week_5_Araling_Panlipunan_8.pdf", 
"assets/module/JuniorHighSchool/Grade 8/AP/Quarter 3/Unit_3_Week_3_Araling_Panlipunan_8.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Filipino/Quarter 1/Q1_W1_Filipino8.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Filipino/Quarter 1/Q1_W2_Filipino8.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Filipino/Quarter 1/Q1_W3_Filipino8.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Filipino/Quarter 1/Q1_W4_Filipino8.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Filipino/Quarter 1/Q1_W5_Filipino8.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Filipino/Quarter 1/Q1_W6_Filipino8.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Filipino/Quarter 1/Q1_W7_Filipino8.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Filipino/Quarter 1/Q1_W8_Filipino8..pdf", 
"assets/module/JuniorHighSchool/Grade 8/Filipino/Quarter 3/Q3_W1_Filipino8.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Filipino/Quarter 3/Q3_W2_Filipino8.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Filipino/Quarter 3/Q3_W3_Filipino8.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Filipino/Quarter 3/Q3_W4_Filipino8.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Filipino/Quarter 3/Q3_W5_Filipino8.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Filipino/Quarter 3/Q3_W6_Filipino8.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Filipino/Quarter 3/Q3_W7_Filipino8.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Filipino/Quarter 2/Q2_W1-2_Filipino8 (1).pdf", 
"assets/module/JuniorHighSchool/Grade 8/Filipino/Quarter 2/Q2_W3-Filipino8.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Filipino/Quarter 2/Q2_W4-5_Filipino8.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Filipino/Quarter 2/Q2_W6-7_Filipino8.pdf", 
"assets/module/JuniorHighSchool/Grade 8/MAPEH/Quarter 4/Q4 MAPEH 8 WEEK 1 MUSIC.pdf", 
"assets/module/JuniorHighSchool/Grade 8/MAPEH/Quarter 4/Q4 MAPEH 8 WEEK 2 ARTS.pdf", 
"assets/module/JuniorHighSchool/Grade 8/MAPEH/Quarter 4/Q4 MAPEH 8 WEEK 3 P.E..pdf", 
"assets/module/JuniorHighSchool/Grade 8/MAPEH/Quarter 4/Q4 MAPEH 8 WEEK 4 HEALTH.pdf", 
"assets/module/JuniorHighSchool/Grade 8/MAPEH/Quarter 1/Q1 MAPEH 8 WEEK 1 MUSIC.pdf", 
"assets/module/JuniorHighSchool/Grade 8/MAPEH/Quarter 1/Q1 MAPEH 8 WEEK 2 ARTS.pdf", 
"assets/module/JuniorHighSchool/Grade 8/MAPEH/Quarter 1/Q1 MAPEH 8 WEEK 3 PE.pdf", 
"assets/module/JuniorHighSchool/Grade 8/MAPEH/Quarter 1/Q1 MAPEH 8 WEEK 4 HEALTH.pdf", 
"assets/module/JuniorHighSchool/Grade 8/MAPEH/Quarter 1/Q1 MAPEH 8 WEEK 5 MUSIC.pdf", 
"assets/module/JuniorHighSchool/Grade 8/MAPEH/Quarter 1/Q1 MAPEH 8 WEEK 6 ARTS.pdf", 
"assets/module/JuniorHighSchool/Grade 8/MAPEH/Quarter 1/Q1 MAPEH 8 WEEK 7 PE.pdf", 
"assets/module/JuniorHighSchool/Grade 8/MAPEH/Quarter 1/Q1 MAPEH 8 WEEK 8 HEALTH.pdf", 
"assets/module/JuniorHighSchool/Grade 8/MAPEH/Quarter 2/Q2 MAPEH 8 WEEK 1 MUSIC.pdf", 
"assets/module/JuniorHighSchool/Grade 8/MAPEH/Quarter 2/Q2 MAPEH 8 WEEK 2 ARTS.pdf", 
"assets/module/JuniorHighSchool/Grade 8/MAPEH/Quarter 2/Q2 MAPEH 8 WEEK 3 P.E..pdf", 
"assets/module/JuniorHighSchool/Grade 8/MAPEH/Quarter 2/Q2 MAPEH 8 WEEK 4 HEALTH.pdf", 
"assets/module/JuniorHighSchool/Grade 8/MAPEH/Quarter 2/Q2 MAPEH 8 WEEK 5 MUSIC.pdf", 
"assets/module/JuniorHighSchool/Grade 8/MAPEH/Quarter 3/Q3 MAPEH 8 WEEK 1 MUSIC.pdf", 
"assets/module/JuniorHighSchool/Grade 8/MAPEH/Quarter 3/Q3 MAPEH 8 WEEK 2 ARTS.pdf", 
"assets/module/JuniorHighSchool/Grade 8/MAPEH/Quarter 3/Q3 MAPEH 8 WEEK 3 P.E..pdf", 
"assets/module/JuniorHighSchool/Grade 8/MAPEH/Quarter 3/Q3 MAPEH 8 WEEK 4 HEALTH.pdf", 
"assets/module/JuniorHighSchool/Grade 8/MAPEH/Quarter 3/Q3 MAPEH 8 WEEK 5 MUSIC.pdf", 
"assets/module/JuniorHighSchool/Grade 8/MAPEH/Quarter 3/Q3 MAPEH 8 WEEK 6 ARTS.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Mathematics/QUARTER 1/G8 Q1M5.1.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Mathematics/QUARTER 1/G8 Q1M5.2 CHAPTER REVIEW.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Mathematics/QUARTER 1/G8 Q1M5.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Mathematics/QUARTER 1/G8 Q1W1.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Mathematics/QUARTER 1/G8 Q1W2.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Mathematics/QUARTER 1/G8 Q1W3.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Mathematics/QUARTER 1/M8 Q1W4.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Mathematics/QUARTER 2/G8 Q2M1-2.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Mathematics/QUARTER 2/G8 Q2M3-4.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Mathematics/QUARTER 3/G8 Q3M1-2.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Mathematics/QUARTER 3/G8 Q3M3-4.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Mathematics/QUARTER 3/G8 Q3M5-6.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Science/Sci8_Curriculum Map.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Science/Sci8_Curriculum TImeline.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Science/Sci8_D.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Science/Quarter 1/Sci8_Q1_Mod1.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Science/Quarter 1/Sci8_Q1_Mod2.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Science/Quarter 1/Sci8_Q1_Mod3.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Science/Quarter 1/Sci8_Q1_Mod4.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Science/Quarter 1/Sci8_Q1_Mod5.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Science/Quarter 1/Sci8_Q1_Mod6.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Science/Quarter 3/Sci8_Q3_Mod2.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Science/Quarter 3/Sci8_Q3_Mod1.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Science/Quarter 3/Sci8_Q3_Mod3.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Science/Quarter 3/Sci8_Q3_Mod5.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Science/Quarter 3/Sci8_Q3_Mod4.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Science/Quarter 3/Sci8_Q3_Mod6.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Science/Quarter 4/Sci8_Q4_Mod1.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Science/Quarter 4/Sci8_Q4_Mod2.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Science/Quarter 2/Sci8_Q2_Mod1.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Science/Quarter 2/Sci8_Q2_Mod2.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Science/Quarter 2/Sci8_Q2_Mod3.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Science/Quarter 2/Sci8_Q2_Mod4.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Science/Quarter 2/Sci8_Q2_Mod5.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Science/Quarter 2/Sci8_Q2_Mod6.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Values/Quarter 4/Q4_VALUES_EDUCATION_WK1_MOD1.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Values/Quarter 4/Q4_VALUES_EDUCATION_WK3_MOD2.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Values/Quarter 4/Q4_VALUES_EDUCATION_WK4_MOD3.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Values/Quarter 4/Q4_VALUES_EDUCATION_WK5_MOD4.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Values/Quarter 1/Q1_VALUES_EDUCATION_WK1_MOD1.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Values/Quarter 1/Q1_VALUES_EDUCATION_WK2_MOD2.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Values/Quarter 1/Q1_VALUES_EDUCATION_WK3_MOD3.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Values/Quarter 1/Q1_VALUES_EDUCATION_WK4_MOD4.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Values/Quarter 1/Q1_VALUES_EDUCATION_WK5_MOD5.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Values/Quarter 1/Q1_VALUES_EDUCATION_WK7_MOD7.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Values/Quarter 2/Q2_VALUES_EDUCATION_WK1_MOD1.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Values/Quarter 2/Q2_VALUES_EDUCATION_WK2_MOD2.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Values/Quarter 2/Q2_VALUES_EDUCATION_WK3_MOD3.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Values/Quarter 2/Q2_VALUES_EDUCATION_WK4_MOD4.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Values/Quarter 2/Q2_VALUES_EDUCATION_WK5_MOD5.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Values/Quarter 3/Q3_VALUES_EDUCATION_WK1_MOD1.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Values/Quarter 3/Q3_VALUES_EDUCATION_WK2_MOD2.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Values/Quarter 3/Q3_VALUES_EDUCATION_WK3_MOD3.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Values/Quarter 3/Q3_VALUES_EDUCATION_WK4_MOD4.pdf", 
"assets/module/JuniorHighSchool/Grade 8/Values/Quarter 3/Q3_VALUES_EDUCATION_WK5_MOD5.pdf", 
"assets/module/JuniorHighSchool/Grade 8/English/Quarter 1/English8_Q1_Week 1.pdf", 
"assets/module/JuniorHighSchool/Grade 8/English/Quarter 1/English8_Q1_Week 2.pdf", 
"assets/module/JuniorHighSchool/Grade 8/English/Quarter 1/English8_Q1_Week 3.pdf", 
"assets/module/JuniorHighSchool/Grade 8/English/Quarter 1/English8_Q1_Week 4.pdf", 
"assets/module/JuniorHighSchool/Grade 8/English/Quarter 1/English8_Q1_Week 5.pdf", 
"assets/module/JuniorHighSchool/Grade 8/English/Quarter 1/English8_Q1_Week 6.pdf", 
"assets/module/JuniorHighSchool/Grade 8/English/Quarter 3/English8_Q3_W1.pdf", 
"assets/module/JuniorHighSchool/Grade 8/English/Quarter 3/English8_Q3_W2.pdf", 
"assets/module/JuniorHighSchool/Grade 8/English/Quarter 3/English8_Q3_W3.pdf", 
"assets/module/JuniorHighSchool/Grade 8/English/Quarter 3/English8_Q3_W4.pdf", 
"assets/module/JuniorHighSchool/Grade 8/English/Quarter 3/English8_Q3_W5.pdf", 
"assets/module/JuniorHighSchool/Grade 8/English/Quarter 3/English8_Q3_W6.pdf", 
"assets/module/JuniorHighSchool/Grade 8/English/Quarter 4/English8_Q4_W1.pdf", 
"assets/module/JuniorHighSchool/Grade 8/English/Quarter 4/English8_Q4_W2.pdf", 
"assets/module/JuniorHighSchool/Grade 8/English/Quarter 4/English8_Q4_W3.pdf", 
"assets/module/JuniorHighSchool/Grade 8/English/Quarter 4/English8_Q4_W4.pdf", 
"assets/module/JuniorHighSchool/Grade 8/English/Quarter 4/English8_Q4_W5.pdf", 
"assets/module/JuniorHighSchool/Grade 8/English/Quarter 2/English-8_Week-1.pdf", 
"assets/module/JuniorHighSchool/Grade 8/English/Quarter 2/English-8_Week-2.pdf", 
"assets/module/JuniorHighSchool/Grade 8/English/Quarter 2/English-8_Week-3.pdf", 
"assets/module/JuniorHighSchool/Grade 8/English/Quarter 2/English-8_Week-4.pdf", 
"assets/module/JuniorHighSchool/Grade 8/English/Quarter 2/English-8_Week-5.pdf", 
"assets/module/JuniorHighSchool/Grade 8/English/Quarter 2/English-8_Week-7.pdf", 
"assets/module/JuniorHighSchool/Grade 8/English/Quarter 2/English-8_Week-8.pdf", 
"assets/module/JuniorHighSchool/Grade 8/English/Quarter 2/English-8_Week-6.pdf",]

export const FilesElementary = [
    'assets/module/elementary/Grade 1/QUARTER 1/WEEK 1-2/ARALING PANLIPUNAN-MODULE 1.pdf',
    'assets/module/elementary/Grade 1/QUARTER 1/WEEK 1-2/ENGLISH-MODULE 1.pdf',
    'assets/module/elementary/Grade 1/QUARTER 1/WEEK 1-2/FILIPINO-MODULE 1.pdf',
    'assets/module/elementary/Grade 1/QUARTER 1/WEEK 1-2/MAPEH-MODULE1.pdf',
    'assets/module/elementary/Grade 1/QUARTER 1/WEEK 1-2/MATH-MODULE 1.pdf',
    'assets/module/elementary/Grade 1/QUARTER 1/WEEK 1-2/MTBMLE-MODULE 1.pdf',
    'assets/module/elementary/Grade 1/QUARTER 1/WEEK 1-2/SCIENCE-MODULE 1.pdf',
    'assets/module/elementary/Grade 1/QUARTER 1/WEEK 1-2/TRACE-JESTIN.pdf',
    'assets/module/elementary/Grade 1/QUARTER 1/WEEK 1-2/TRACE-MOURISETT.pdf',
    'assets/module/elementary/Grade 1/QUARTER 1/WEEK 1-2/TRACE-NHORIZZ.pdf',
    'assets/module/elementary/Grade 1/QUARTER 1/WEEK 1-2/TRACING WORDS.pdf',
    'assets/module/elementary/Grade 1/QUARTER 1/WEEK 3-4/ARALING PANLIPUNAN-MODULE 2.pdf',
    'assets/module/elementary/Grade 1/QUARTER 1/WEEK 3-4/ENGLISH-MODULE 2.pdf',
    'assets/module/elementary/Grade 1/QUARTER 1/WEEK 3-4/FILIPINO - MODULE 2.pdf',
    'assets/module/elementary/Grade 1/QUARTER 1/WEEK 3-4/MAPEH-MODULE2.pdf',
    'assets/module/elementary/Grade 1/QUARTER 1/WEEK 3-4/MATH - MODULE 2.pdf',
    'assets/module/elementary/Grade 1/QUARTER 1/WEEK 3-4/MTBMLE-MODULE 2.pdf',
    'assets/module/elementary/Grade 1/QUARTER 1/WEEK 3-4/SCIENCE-MODULE 2.pdf',
    'assets/module/elementary/Grade 1/QUARTER 1/WEEK 3-4/TRACING WORDS2.pdf',
    'assets/module/elementary/Grade 1/QUARTER 1/WEEK 3-4/VALUES - MODULE 1.pdf',
    'assets/module/elementary/Grade 1/QUARTER 1/WEEK 5-6/ARALING PANLIPUNAN-MODULE 3.pdf',
    'assets/module/elementary/Grade 1/QUARTER 1/WEEK 5-6/ENGLISH-MODULE 3.pdf',
    'assets/module/elementary/Grade 1/QUARTER 1/WEEK 5-6/FILIPINO-MODULE 3.pdf',
    'assets/module/elementary/Grade 1/QUARTER 1/WEEK 5-6/MAPEH-MODULE3.pdf',
    'assets/module/elementary/Grade 1/QUARTER 1/WEEK 5-6/MATH-MODULE 3.pdf',
    'assets/module/elementary/Grade 1/QUARTER 1/WEEK 5-6/READING (ENGLISH).pdf',
    'assets/module/elementary/Grade 1/QUARTER 1/WEEK 5-6/SCIENCE-MODULE 3.pdf',
    'assets/module/elementary/Grade 1/QUARTER 1/WEEK 5-6/VALUES-MODULE 2.pdf',
    'assets/module/elementary/Grade 1/QUARTER 2/WEEK 1-2/ARALING PANLIPUNAN-MODULE 1.pdf',
    'assets/module/elementary/Grade 1/QUARTER 2/WEEK 1-2/ENGLISH - MODULE 1.pdf',
    'assets/module/elementary/Grade 1/QUARTER 2/WEEK 1-2/FILIPINO-MODULE 1.pdf',
    'assets/module/elementary/Grade 1/QUARTER 2/WEEK 1-2/MAPEH-MODULE 1.pdf',
    'assets/module/elementary/Grade 1/QUARTER 2/WEEK 1-2/MATH-MODULE 1.pdf',
    'assets/module/elementary/Grade 1/QUARTER 2/WEEK 1-2/MTB-MLE - MODULE 1.pdf',
    'assets/module/elementary/Grade 1/QUARTER 2/WEEK 1-2/SCIENCE - MODULE 1.pdf',
    'assets/module/elementary/Grade 1/QUARTER 2/WEEK 1-2/VALUES - MODULE 1.pdf',
    'assets/module/elementary/Grade 1/QUARTER 1/WEEK 7-8/ARALING PANLIPUNAN-MODULE 4.pdf',
    'assets/module/elementary/Grade 1/QUARTER 1/WEEK 7-8/ENGLISH-MODULE 4.pdf',
    'assets/module/elementary/Grade 1/QUARTER 1/WEEK 7-8/FILIPINO-MODULE 4.pdf',
    'assets/module/elementary/Grade 1/QUARTER 1/WEEK 7-8/MATH-MODULE 4.pdf',
    'assets/module/elementary/Grade 1/QUARTER 1/WEEK 7-8/MTBMLE-MODULE 3.pdf',
    'assets/module/elementary/Grade 1/QUARTER 1/WEEK 7-8/SCIENCE-MODULE 4.pdf',
    'assets/module/elementary/Grade 1/QUARTER 2/WEEK 3/ENGLISH - MODULE 2.pdf',
    'assets/module/elementary/Grade 1/QUARTER 2/WEEK 3/FILIPINO-MODULE 2.pdf',
    'assets/module/elementary/Grade 1/QUARTER 2/WEEK 3/MATH-MODULE 2.pdf',
    'assets/module/elementary/Grade 2/QUARTER 1/WEEK 3-4/ARALING PANLIPUNAN-MODULE 2.pdf',
    'assets/module/elementary/Grade 2/QUARTER 1/WEEK 3-4/ENGLISH-MODULE 2.pdf',
    'assets/module/elementary/Grade 2/QUARTER 1/WEEK 3-4/FILIPINO-MODULE 2.pdf',
    'assets/module/elementary/Grade 2/QUARTER 1/WEEK 3-4/MATH-MODULE 2.pdf',
    'assets/module/elementary/Grade 2/QUARTER 1/WEEK 3-4/MTBMLE-MODULE 2.pdf',
    'assets/module/elementary/Grade 2/QUARTER 1/WEEK 3-4/SCIENCE-MODULE 2.pdf',
    'assets/module/elementary/Grade 2/QUARTER 1/WEEK 3-4/VALUES-MODULE 2.pdf',
    'assets/module/elementary/Grade 2/QUARTER 1/WEEK 3-4/WRITING LETTER Dd.pdf',
    'assets/module/elementary/Grade 2/QUARTER 1/WEEK 3-4/WRITING LETTER Ee.pdf',
    'assets/module/elementary/Grade 2/QUARTER 1/WEEK 3-4/WRITING LETTER Ff.pdf',
    'assets/module/elementary/Grade 2/QUARTER 2/WEEK 1-2/ARALING PANLIPUNAN - MODULE 1.pdf',
    'assets/module/elementary/Grade 2/QUARTER 2/WEEK 1-2/ENGLISH-MODULE 1.pdf',
    'assets/module/elementary/Grade 2/QUARTER 2/WEEK 1-2/FILIPINO - MODULE 1.pdf',
    'assets/module/elementary/Grade 2/QUARTER 2/WEEK 1-2/MAPEH - MODULE 1.pdf',
    'assets/module/elementary/Grade 2/QUARTER 2/WEEK 1-2/MATH-MODULE 1.pdf',
    'assets/module/elementary/Grade 2/QUARTER 2/WEEK 1-2/SCIENCE - MODULE 1.pdf',
    'assets/module/elementary/Grade 2/QUARTER 2/WEEK 1-2/VALUES - MODULE 1.pdf',
    'assets/module/elementary/Grade 2/QUARTER 1/WEEK 7-8/ENGLISH-MODULE 4.pdf',
    'assets/module/elementary/Grade 2/QUARTER 1/WEEK 7-8/FILIPINO-MODULE 4.pdf',
    'assets/module/elementary/Grade 2/QUARTER 1/WEEK 7-8/MAPEH-MODULE 3.pdf',
    'assets/module/elementary/Grade 2/QUARTER 1/WEEK 7-8/MATH-MODULE 4.pdf',
    'assets/module/elementary/Grade 2/QUARTER 1/WEEK 7-8/MTBMLE-MODULE 3.pdf',
    'assets/module/elementary/Grade 2/QUARTER 1/WEEK 7-8/SCIENCE-MODULE 4.pdf',
    'assets/module/elementary/Grade 2/QUARTER 1/WEEK 5-6/ARALING PANLIPUNAN-MODULE 3.pdf',
    'assets/module/elementary/Grade 2/QUARTER 1/WEEK 5-6/ENGLISH-MODULE 3.pdf',
    'assets/module/elementary/Grade 2/QUARTER 1/WEEK 5-6/FILIPINO-MODULE 3.pdf',
    'assets/module/elementary/Grade 2/QUARTER 1/WEEK 5-6/GRAMMAR AND WRITING (ENGLISH).pdf',
    'assets/module/elementary/Grade 2/QUARTER 1/WEEK 5-6/MAPEH-MODULE 2.pdf',
    'assets/module/elementary/Grade 2/QUARTER 1/WEEK 5-6/MATH-MODULE 3.pdf',
    'assets/module/elementary/Grade 2/QUARTER 1/WEEK 5-6/SCIENCE-MODULE 3.pdf',
    'assets/module/elementary/Grade 2/QUARTER 1/WEEK 5-6/VALUES-MODULE 3.pdf',
    'assets/module/elementary/Grade 2/QUARTER 2/WEEK 3/ENGLISH-MODULE 2.pdf',
    'assets/module/elementary/Grade 2/QUARTER 2/WEEK 3/MATH-MODULE 2.pdf',
    'assets/module/elementary/Grade 2/QUARTER 2/WEEK 3/SCIENCE - MODULE 2.pdf',
    'assets/module/elementary/Grade 2/QUARTER 2/WEEK 3/VALUES - MODULE 2.pdf',
    "assets/module/elementary/Grade 3/Quarter 1/Week 1 - 2/Araling Panlipunan.pdf",
    "assets/module/elementary/Grade 3/Quarter 1/Week 1 - 2/Arts.pdf",
    "assets/module/elementary/Grade 3/Quarter 1/Week 1 - 2/English.pdf",
    "assets/module/elementary/Grade 3/Quarter 1/Week 1 - 2/Filipino.pdf",
    "assets/module/elementary/Grade 3/Quarter 1/Week 1 - 2/Health.pdf",
    "assets/module/elementary/Grade 3/Quarter 1/Week 1 - 2/MTB-MLE.pdf",
    "assets/module/elementary/Grade 3/Quarter 1/Week 1 - 2/Mathematics.pdf",
    "assets/module/elementary/Grade 3/Quarter 1/Week 1 - 2/Music.pdf",
    "assets/module/elementary/Grade 3/Quarter 1/Week 1 - 2/Physical Education.pdf",
    "assets/module/elementary/Grade 3/Quarter 1/Week 1 - 2/Science.pdf",
    "assets/module/elementary/Grade 3/Quarter 1/Week 1 - 2/Values Education.pdf",
    "assets/module/elementary/Grade 3/Quarter 1/Week 3 - 4/Araling Panlipunan3_Q1_ Week 3 _ 4.pdf",
    "assets/module/elementary/Grade 3/Quarter 1/Week 3 - 4/Arts3_Q1_Week 3 _ 4.pdf",
    "assets/module/elementary/Grade 3/Quarter 1/Week 3 - 4/English3_Q1_Week 3 _ 4.pdf",
    "assets/module/elementary/Grade 3/Quarter 1/Week 3 - 4/Filipino3_Q1_Week 3 _ 4.pdf",
    "assets/module/elementary/Grade 3/Quarter 1/Week 3 - 4/Health3_Q1_Week 3 _ 4.pdf",
    "assets/module/elementary/Grade 3/Quarter 1/Week 3 - 4/MTB-MLE3_Q1_Week 3 _ 4.pdf",
    "assets/module/elementary/Grade 3/Quarter 1/Week 3 - 4/Mathematics3_Q1_Week 3 _ 4.pdf",
    "assets/module/elementary/Grade 3/Quarter 1/Week 3 - 4/Music3_Q1_Week 3 _ 4.pdf",
    "assets/module/elementary/Grade 3/Quarter 1/Week 3 - 4/PE3_Q1_Week 3 _ 4.pdf",
    "assets/module/elementary/Grade 3/Quarter 1/Week 3 - 4/Science3_Q1_Week 3 _ 4.pdf",
    "assets/module/elementary/Grade 3/Quarter 1/Week 3 - 4/Values Education3_Q1_Week 3 _ 4.pdf",
    "assets/module/elementary/Grade 3/Quarter 1/Week 5 - 6/Araling Panlipunan3_Q1_ Week 5 - 6.pdf",
    "assets/module/elementary/Grade 3/Quarter 1/Week 5 - 6/Arts3_Q1_Week 5 - 6.pdf",
    "assets/module/elementary/Grade 3/Quarter 1/Week 5 - 6/English3_Q1_Week 5 - 6.pdf",
    "assets/module/elementary/Grade 3/Quarter 1/Week 5 - 6/Filipino3_Q1_Week 5 - 6.pdf",
    "assets/module/elementary/Grade 3/Quarter 1/Week 5 - 6/Health3_Q1_Week 5 - 6.pdf",
    "assets/module/elementary/Grade 3/Quarter 1/Week 5 - 6/MTB-MLE3_Q1_Week 5 - 6.pdf",
    "assets/module/elementary/Grade 3/Quarter 1/Week 5 - 6/Mathematics3_Q1_Week 5 - 6.pdf",
    "assets/module/elementary/Grade 3/Quarter 1/Week 5 - 6/Music3_Q1_Week 5 - 6.pdf",
    "assets/module/elementary/Grade 3/Quarter 1/Week 5 - 6/PE3_Q1_Week 5 - 6.pdf",
    "assets/module/elementary/Grade 3/Quarter 1/Week 5 - 6/Values Education3_Q1_Week 5 - 6.pdf",
    "assets/module/elementary/Grade 3/Quarter 2/Week 1 - 2/AralingPanlipunan3_Q2_ Week 1-2.pdf",
    "assets/module/elementary/Grade 3/Quarter 2/Week 1 - 2/Arts3_Q2_Week 1-2.pdf",
    "assets/module/elementary/Grade 3/Quarter 2/Week 1 - 2/English3_Q2_Week 1-2.pdf",
    "assets/module/elementary/Grade 3/Quarter 2/Week 1 - 2/Filipino3_Q2_Week 1-2.pdf",
    "assets/module/elementary/Grade 3/Quarter 2/Week 1 - 2/Health3_Q2_Week 1-2.pdf",
    "assets/module/elementary/Grade 3/Quarter 2/Week 1 - 2/MTB-MLE3_Q2_Week 1.pdf",
    "assets/module/elementary/Grade 3/Quarter 2/Week 1 - 2/Mathematics3_Q2_Week 1-2.pdf",
    "assets/module/elementary/Grade 3/Quarter 2/Week 1 - 2/Music3_Q2_Week 1-2.pdf",
    "assets/module/elementary/Grade 3/Quarter 2/Week 1 - 2/PE3_Q2_Week 1-2.pdf",
    "assets/module/elementary/Grade 3/Quarter 2/Week 1 - 2/Science3_Q2_Week 1-2.pdf",
    "assets/module/elementary/Grade 3/Quarter 2/Week 1 - 2/Values Education3_Q2_Week 1-4.pdf",
    "assets/module/elementary/Grade 3/Quarter 2/Week 3 - 4/AralingPanlipunan3_Q2_ Week 3 - 4.pdf",
    "assets/module/elementary/Grade 3/Quarter 2/Week 3 - 4/Arts3_Q2_Week 3-4.pdf",
    "assets/module/elementary/Grade 3/Quarter 2/Week 3 - 4/English3_Q2_Week 3-4.pdf",
    "assets/module/elementary/Grade 3/Quarter 2/Week 3 - 4/Filipino3_Q2_Week 3-5.pdf",
    "assets/module/elementary/Grade 3/Quarter 2/Week 3 - 4/Health3_Q2_Week 3 - 5.pdf",
    "assets/module/elementary/Grade 3/Quarter 2/Week 3 - 4/MTB-MLE3_Q2_Week 3-4.pdf",
    "assets/module/elementary/Grade 3/Quarter 2/Week 3 - 4/Mathematics3_Q2_Week 3-4.pdf",
    "assets/module/elementary/Grade 3/Quarter 2/Week 3 - 4/Music3_Q2_Week 3-4.pdf",
    "assets/module/elementary/Grade 3/Quarter 2/Week 3 - 4/PE3_Q2_Week 3-5.pdf",
    "assets/module/elementary/Grade 3/Quarter 2/Week 3 - 4/Science3_Q2_Week 3-4.pdf",
    "assets/module/elementary/Grade 3/Quarter 2/Week 5 - 6/AralingPanlipunan3_Q2_ Week 5 -  6.pdf",
    "assets/module/elementary/Grade 3/Quarter 2/Week 5 - 6/Arts3_Q2_Week 5-6.pdf",
    "assets/module/elementary/Grade 3/Quarter 2/Week 5 - 6/English3_Q2_Week 5 - 6.pdf",
    "assets/module/elementary/Grade 3/Quarter 2/Week 5 - 6/Filipino3_Q2_Week 6.pdf",
    "assets/module/elementary/Grade 3/Quarter 2/Week 5 - 6/Health3_Q2_Week 6.pdf",
    "assets/module/elementary/Grade 3/Quarter 2/Week 5 - 6/MTB-MLE3_Q2_Week 5 - 6.pdf",
    "assets/module/elementary/Grade 3/Quarter 2/Week 5 - 6/Mathematics3_Q2_Week 5.pdf",
    "assets/module/elementary/Grade 3/Quarter 2/Week 5 - 6/Mathematics3_Q2_Week 6.pdf",
    "assets/module/elementary/Grade 3/Quarter 2/Week 5 - 6/Music3_Q2_Week 5.pdf",
    "assets/module/elementary/Grade 3/Quarter 2/Week 5 - 6/PE3_Q2_Week 6-8.pdf",
    "assets/module/elementary/Grade 3/Quarter 2/Week 5 - 6/Science3_Q2_Week 5 - 6.pdf",
    "assets/module/elementary/Grade 3/Quarter 2/Week 5 - 6/Values Education3_Q3_Week 5 - 6.pdf",
    "assets/module/elementary/Grade 3/Quarter 3/Week 1 - 2/AralingPanlipunan3_Q3_ Week 1 - 3.pdf",
    "assets/module/elementary/Grade 3/Quarter 3/Week 1 - 2/Arts3_Q3_Week 1 - 3.pdf",
    "assets/module/elementary/Grade 3/Quarter 3/Week 1 - 2/English3_Q3_Week 1 - 2.pdf",
    "assets/module/elementary/Grade 3/Quarter 3/Week 1 - 2/Filipino3_Q3_Week 1 - 2.pdf",
    "assets/module/elementary/Grade 3/Quarter 3/Week 1 - 2/Health3_Q3_Week 1 - 2.pdf",
    "assets/module/elementary/Grade 3/Quarter 3/Week 1 - 2/MTB-MLE3_Q3_Week 1 - 2.pdf",
    "assets/module/elementary/Grade 3/Quarter 3/Week 1 - 2/Mathematics3_Q3_Week 1 - 2.pdf",
    "assets/module/elementary/Grade 3/Quarter 3/Week 1 - 2/Music3_Q3_Week 1- 2.pdf",
    "assets/module/elementary/Grade 3/Quarter 3/Week 1 - 2/PE3_Q3_Week 1 - 2.pdf",
    "assets/module/elementary/Grade 3/Quarter 3/Week 1 - 2/Science3_Q3_Week 1 - 2.pdf",
    "assets/module/elementary/Grade 3/Quarter 3/Week 1 - 2/Values Education3_Q3_Week 1 - 2.pdf",
    "assets/module/elementary/Grade 3/Quarter 3/Week 3 - 4/Arts3_Q3_Week 4.pdf",
    "assets/module/elementary/Grade 3/Quarter 3/Week 3 - 4/English3_Q3_Week 3 - 4.pdf",
    "assets/module/elementary/Grade 3/Quarter 3/Week 3 - 4/Filipino3_Q3_Week 3 - 4.pdf",
    "assets/module/elementary/Grade 3/Quarter 3/Week 3 - 4/Health3_Q3_Week 3 - 4.pdf",
    "assets/module/elementary/Grade 3/Quarter 3/Week 3 - 4/Mathematics3_Q3_Week 3 - 4.pdf",
    "assets/module/elementary/Grade 3/Quarter 3/Week 3 - 4/Music3_Q3_Week 3 - 4.pdf",
    "assets/module/elementary/Grade 3/Quarter 3/Week 3 - 4/PE3_Q3_Week 3 - 5.pdf",
    "assets/module/elementary/Grade 3/Quarter 3/Week 3 - 4/Science3_Q3_Week 3 - 4.pdf",
    "assets/module/elementary/Grade 3/Quarter 3/Week 3 - 4/Values Education3_Q3_Week 3 - 4.pdf",
    "assets/module/elementary/Grade 3/Quarter 3/Week 5 - 6/Arts3_Q3_Week 5 - 6.pdf",
    "assets/module/elementary/Grade 3/Quarter 3/Week 5 - 6/English3_Q3_Week 5 - 6.pdf",
    "assets/module/elementary/Grade 3/Quarter 3/Week 5 - 6/Filipino3_Q3_Week 5 - 6.pdf",
    "assets/module/elementary/Grade 3/Quarter 3/Week 5 - 6/Health3_Q3_Week 5 - 6.pdf",
    "assets/module/elementary/Grade 3/Quarter 3/Week 5 - 6/MTB-MLE3_Q3_Week 5 - 6.pdf",
    "assets/module/elementary/Grade 3/Quarter 3/Week 5 - 6/Mathematics3_Q3_Week 5 - 6.pdf",
    "assets/module/elementary/Grade 3/Quarter 3/Week 5 - 6/Music3_Q3_Week 5 - 6.pdf",
    "assets/module/elementary/Grade 3/Quarter 3/Week 5 - 6/Science3_Q3_Week 5 - 6.pdf",
    "assets/module/elementary/Grade 3/Quarter 3/Week 5 - 6/Values Education3_Q3_Week 5 - 6.pdf",
    "assets/module/elementary/Grade 3/Quarter 4/Week 1 - 2/AralingPanlipunan3_Q4_ Week 1 - 2.pdf",
    "assets/module/elementary/Grade 3/Quarter 4/Week 1 - 2/Arts3_Q4_Week 1 - 4.pdf",
    "assets/module/elementary/Grade 3/Quarter 4/Week 1 - 2/English3_Q4_Week 1 - 2.pdf",
    "assets/module/elementary/Grade 3/Quarter 4/Week 1 - 2/Filipino3_Q4_Week 1.pdf",
    "assets/module/elementary/Grade 3/Quarter 4/Week 1 - 2/Health3_Q4_Week 1 - 2.pdf",
    "assets/module/elementary/Grade 3/Quarter 4/Week 1 - 2/MTB-MLE3_Q4_Week 1 - 2.pdf",
    "assets/module/elementary/Grade 3/Quarter 4/Week 1 - 2/Mathematics3_Q4_Week 1 - 2.pdf",
    "assets/module/elementary/Grade 3/Quarter 4/Week 1 - 2/Music3_Q4_Week 1 - 2.pdf",
    "assets/module/elementary/Grade 3/Quarter 4/Week 1 - 2/PE3_Q4_Week 1 - 2.pdf",
    "assets/module/elementary/Grade 3/Quarter 4/Week 1 - 2/Science3_Q4_Week 1 - 2.pdf",
    "assets/module/elementary/Grade 3/Quarter 4/Week 1 - 2/Values Education3_Q4_Week 1 - 2.pdf",
    "assets/module/elementary/Grade 3/Quarter 4/Week 5 - 6/AralingPanlipunan3_Q4_ Week 5 - 6.pdf",
    "assets/module/elementary/Grade 3/Quarter 4/Week 5 - 6/Arts3_Q4_Week 5.pdf",
    "assets/module/elementary/Grade 3/Quarter 4/Week 5 - 6/English3_Q4_Week 5 - 6.pdf",
    "assets/module/elementary/Grade 3/Quarter 4/Week 5 - 6/Filipino3_Q4_Week 5 - 6.pdf",
    "assets/module/elementary/Grade 3/Quarter 4/Week 5 - 6/Health3_Q4_Week 5 - 6.pdf",
    "assets/module/elementary/Grade 3/Quarter 4/Week 5 - 6/MTB-MLE3_Q4_Week 5 - 6.pdf",
    "assets/module/elementary/Grade 3/Quarter 4/Week 5 - 6/Mathematics3_Q4_Week 5 - 6.pdf",
    "assets/module/elementary/Grade 3/Quarter 4/Week 5 - 6/Music3_Q4_Week 5 - 6.pdf",
    "assets/module/elementary/Grade 3/Quarter 4/Week 5 - 6/Science3_Q4_Week 5 - 6.pdf",
    "assets/module/elementary/Grade 3/Quarter 4/Week 5 - 6/Values Education3_Q4_Week 5 - 6.pdf",
    "assets/module/elementary/Grade 4/Quarter 1/Week 5 - 6/Araling Panlipunan4_Q1_Week 5 - 6.pdf",
    "assets/module/elementary/Grade 4/Quarter 1/Week 5 - 6/Arts4_Q1_Week 5 - 6.pdf",
    "assets/module/elementary/Grade 4/Quarter 1/Week 5 - 6/English4_Q1_Week 5 - 6.pdf",
    "assets/module/elementary/Grade 4/Quarter 1/Week 5 - 6/Filipino4_Q1_Week 5 - 6.pdf",
    "assets/module/elementary/Grade 4/Quarter 1/Week 5 - 6/Mathematics4_Q1_Week 5 - 6.pdf",
    "assets/module/elementary/Grade 4/Quarter 1/Week 5 - 6/Music4_Q1_Week 5 - 6.pdf",
    "assets/module/elementary/Grade 4/Quarter 1/Week 5 - 6/PE4_Q1_Week 5 - 6.pdf",
    "assets/module/elementary/Grade 4/Quarter 1/Week 5 - 6/Science4_Q1_Week 5 - 6.pdf",
    "assets/module/elementary/Grade 4/Quarter 1/Week 5 - 6/TLE4_Q1_Week  5 - 6.pdf",
    "assets/module/elementary/Grade 4/Quarter 1/Week 5 - 6/Values Education4_Q1_Week 5 - 6.pdf",
    "assets/module/elementary/Grade 4/Quarter 2/Week 3 - 4/AralingPanlipunan4_Q2_Week 3-4.pdf",
    "assets/module/elementary/Grade 4/Quarter 2/Week 3 - 4/Arts4_Q2_Week 3-4.pdf",
    "assets/module/elementary/Grade 4/Quarter 2/Week 3 - 4/English4_Q2_Week 4.pdf",
    "assets/module/elementary/Grade 4/Quarter 2/Week 3 - 4/Filipino4_Q2_Week 3-4.pdf",
    "assets/module/elementary/Grade 4/Quarter 2/Week 3 - 4/Health4_Q2_Week 3-4.pdf",
    "assets/module/elementary/Grade 4/Quarter 2/Week 3 - 4/Mathematics4_Q2_Week 3-4.pdf",
    "assets/module/elementary/Grade 4/Quarter 2/Week 3 - 4/Music4_Q2_Week 4.pdf",
    "assets/module/elementary/Grade 4/Quarter 2/Week 3 - 4/Science4_Q2_Week 3-4.pdf",
    "assets/module/elementary/Grade 4/Quarter 2/Week 3 - 4/TLE4_Q2_Week 3-4.pdf",
    "assets/module/elementary/Grade 4/Quarter 2/Week 3 - 4/Values Education4_Q2_Week 3-5.pdf",
    "assets/module/elementary/Grade 4/Quarter 2/Week 5 - 6/AralingPanlipunan4_Q2_Week 5-6.pdf",
    "assets/module/elementary/Grade 4/Quarter 2/Week 5 - 6/Arts4_Q2_Week 5 - 6.pdf",
    "assets/module/elementary/Grade 4/Quarter 2/Week 5 - 6/English4_Q2_Week 5 - 6.pdf",
    "assets/module/elementary/Grade 4/Quarter 2/Week 5 - 6/Filipino4_Q2_Week 5 - 6.pdf",
    "assets/module/elementary/Grade 4/Quarter 2/Week 5 - 6/Health4_Q2_Week 5 - 6.pdf",
    "assets/module/elementary/Grade 4/Quarter 2/Week 5 - 6/Mathematics4_Q2_Week 5 - 6.pdf",
    "assets/module/elementary/Grade 4/Quarter 2/Week 5 - 6/Music4_Q2_Week 5 - 6.pdf",
    "assets/module/elementary/Grade 4/Quarter 2/Week 5 - 6/PE4_Q2_Week 6 - 8.pdf",
    "assets/module/elementary/Grade 4/Quarter 2/Week 5 - 6/Science4_Q2_Week 5 - 6.pdf",
    "assets/module/elementary/Grade 4/Quarter 2/Week 5 - 6/TLE4_Q2_Week 5 - 6.pdf",
    "assets/module/elementary/Grade 4/Quarter 2/Week 5 - 6/Values Education4_Q2_Week 6-8.pdf",
    "assets/module/elementary/Grade 4/Quarter 3/Week 1 - 2/AralingPanlipunan4_Q3_Week 1 - 2.pdf",
    "assets/module/elementary/Grade 4/Quarter 3/Week 1 - 2/Arts4_Q3_Week 1 - 4.pdf",
    "assets/module/elementary/Grade 4/Quarter 3/Week 1 - 2/English4_Q3_Week 1 - 2.pdf",
    "assets/module/elementary/Grade 4/Quarter 3/Week 1 - 2/Filipino4_Q3_Week 1 - 2.pdf",
    "assets/module/elementary/Grade 4/Quarter 3/Week 1 - 2/Health4_Q3_Week 1 - 2.pdf",
    "assets/module/elementary/Grade 4/Quarter 3/Week 1 - 2/Mathematics Lesson.pdf",
    "assets/module/elementary/Grade 4/Quarter 3/Week 1 - 2/Mathematics4_Q3_Week 1 - 2.pdf",
    "assets/module/elementary/Grade 4/Quarter 3/Week 1 - 2/Music4_Q3_Week 1 - 4.pdf",
    "assets/module/elementary/Grade 4/Quarter 3/Week 1 - 2/PE4_Q3_Week 1 - 2.pdf",
    "assets/module/elementary/Grade 4/Quarter 3/Week 1 - 2/TLE4_Q3_Week 1 - 2.pdf",
    "assets/module/elementary/Grade 4/Quarter 3/Week 1 - 2/Values Education4_Q3_Week 1 - 2.pdf",
    "assets/module/elementary/Grade 4/Quarter 3/Week 3 - 4/AralingPanlipunan4_Q3_Week 3 - 4.pdf",
    "assets/module/elementary/Grade 4/Quarter 3/Week 3 - 4/English4_Q3_Week 3.pdf",
    "assets/module/elementary/Grade 4/Quarter 3/Week 3 - 4/English4_Q3_Week 4.pdf",
    "assets/module/elementary/Grade 4/Quarter 3/Week 3 - 4/Filipino4_Q3_Week 3 - 4.pdf",
    "assets/module/elementary/Grade 4/Quarter 3/Week 3 - 4/Health4_Q3_Week 3 - 4.pdf",
    "assets/module/elementary/Grade 4/Quarter 3/Week 3 - 4/Mathematics4_Q3_Week 3 - 4.pdf",
    "assets/module/elementary/Grade 4/Quarter 3/Week 3 - 4/Mathematics4_Q3_Week 3_LESSON.pdf",
    "assets/module/elementary/Grade 4/Quarter 3/Week 3 - 4/Mathematics4_Q3_Week 4_LESSON.pdf",
    "assets/module/elementary/Grade 4/Quarter 3/Week 3 - 4/Science4_Q3_Week 3 - 4.pdf",
    "assets/module/elementary/Grade 4/Quarter 3/Week 3 - 4/TLE4_Q3_Week 3 - 4.pdf",
    "assets/module/elementary/Grade 4/Quarter 3/Week 3 - 4/Values Education4_Q3_Week 3 - 4.pdf",
    "assets/module/elementary/Grade 4/Quarter 3/Week 5 - 6/Arts4_Q3_Week 5 - 6.pdf",
    "assets/module/elementary/Grade 4/Quarter 3/Week 5 - 6/English4_Q3_Week 5 - 6.pdf",
    "assets/module/elementary/Grade 4/Quarter 3/Week 5 - 6/Filipino4_Q3_Week 5 - 6.pdf",
    "assets/module/elementary/Grade 4/Quarter 3/Week 5 - 6/Health4_Q3_Week 5 - 6.pdf",
    "assets/module/elementary/Grade 4/Quarter 3/Week 5 - 6/Mathematics4_Q3_Week 5.pdf",
    "assets/module/elementary/Grade 4/Quarter 3/Week 5 - 6/Mathematics4_Q3_Week 6.pdf",
    "assets/module/elementary/Grade 4/Quarter 3/Week 5 - 6/Music4_Q3_Week 5 - 6.pdf",
    "assets/module/elementary/Grade 4/Quarter 3/Week 5 - 6/PE4_Q3_Week 5 - 6.pdf",
    "assets/module/elementary/Grade 4/Quarter 3/Week 5 - 6/Science4_Q3_Week 5 - 6.pdf",
    "assets/module/elementary/Grade 4/Quarter 3/Week 5 - 6/TLE4_Q3_Week 5 - 6.pdf",
    "assets/module/elementary/Grade 4/Quarter 3/Week 5 - 6/Values Education4_Q3_Week 5 - 6.pdf",
    "assets/module/elementary/Grade 4/Quarter 4/Week 1 - 2/AralingPanlipunan4_Q4_Week 1 - 2.pdf",
    "assets/module/elementary/Grade 4/Quarter 4/Week 1 - 2/Arts4_Q4_Week 1 - 2.pdf",
    "assets/module/elementary/Grade 4/Quarter 4/Week 1 - 2/English4_Q4_Week 1 - 2.pdf",
    "assets/module/elementary/Grade 4/Quarter 4/Week 1 - 2/Filipino4_Q4_Week 1 - 2.pdf",
    "assets/module/elementary/Grade 4/Quarter 4/Week 1 - 2/Health4_Q4_Week 1 - 2.pdf",
    "assets/module/elementary/Grade 4/Quarter 4/Week 1 - 2/Mathematics4_Q4_Week 1 - 2.pdf",
    "assets/module/elementary/Grade 4/Quarter 4/Week 1 - 2/Music4_Q4_Week 1 - 2.pdf",
    "assets/module/elementary/Grade 4/Quarter 4/Week 1 - 2/PE4_Q4_Week 1 - 4.pdf",
    "assets/module/elementary/Grade 4/Quarter 4/Week 1 - 2/Science4_Q4_Week 1 - 2.pdf",
    "assets/module/elementary/Grade 4/Quarter 4/Week 1 - 2/TLE4_Q4_Week 1 - 2.pdf",
    "assets/module/elementary/Grade 4/Quarter 4/Week 1 - 2/Values Education4_Q4_Week 1 - 2.pdf",
    "assets/module/elementary/Grade 4/Quarter 4/Week 5 - 6/AralingPanlipunan_Q4_Week 5 - 6.pdf",
    "assets/module/elementary/Grade 4/Quarter 4/Week 5 - 6/Arts4_Q4_Week 5 - 6.pdf",
    "assets/module/elementary/Grade 4/Quarter 4/Week 5 - 6/English4_Quarter4_Week 5 - 6.pdf",
    "assets/module/elementary/Grade 4/Quarter 4/Week 5 - 6/Filipino4_Q4_Week 5 - 6.pdf",
    "assets/module/elementary/Grade 4/Quarter 4/Week 5 - 6/Mathematics4_Q4_Week 5 - 6.pdf",
    "assets/module/elementary/Grade 4/Quarter 4/Week 5 - 6/Music4_Q4_Week 5 - 6.pdf",
    "assets/module/elementary/Grade 4/Quarter 4/Week 5 - 6/Science_Q4_Week 5 - 6.pdf",
    "assets/module/elementary/Grade 4/Quarter 4/Week 5 - 6/T.L.E4_Q4_Week 5 - 6.pdf",
    "assets/module/elementary/Grade 4/Quarter 4/Week 5 - 6/ValuesEducation4-Quarter4-Week 5 - 6.pdf",
    "assets/module/elementary/Grade 5/Quarter 1/Week 1/AP Q1 W1.pdf",
    "assets/module/elementary/Grade 5/Quarter 1/Week 1/ESP Q1 W1.pdf",
    "assets/module/elementary/Grade 5/Quarter 1/Week 1/English Q1 W1.pdf",
    "assets/module/elementary/Grade 5/Quarter 1/Week 1/Filipino Q1 W1.pdf",
    "assets/module/elementary/Grade 5/Quarter 1/Week 1/Math Q1 W1.pdf",
    "assets/module/elementary/Grade 5/Quarter 1/Week 1/Music Q1 W1.pdf",
    "assets/module/elementary/Grade 5/Quarter 1/Week 1/Science Q1 W1.pdf",
    "assets/module/elementary/Grade 5/Quarter 1/Week 1/TLE Q1 W1.pdf",
    "assets/module/elementary/Grade 5/Quarter 1/Week 2/AP Q1 W2.pdf",
    "assets/module/elementary/Grade 5/Quarter 1/Week 2/Art Q1 W2.pdf",
    "assets/module/elementary/Grade 5/Quarter 1/Week 2/ESP Q1 W2.pdf",
    "assets/module/elementary/Grade 5/Quarter 1/Week 2/English Q1 W2.pdf",
    "assets/module/elementary/Grade 5/Quarter 1/Week 2/Filipino Q1 W2.pdf",
    "assets/module/elementary/Grade 5/Quarter 1/Week 2/Math Q1 W2.pdf",
    "assets/module/elementary/Grade 5/Quarter 1/Week 2/Science Q1 W2.pdf",
    "assets/module/elementary/Grade 5/Quarter 1/Week 2/TLE Q1 W2.pdf",
    "assets/module/elementary/Grade 5/Quarter 1/Week 6/AP Q1 W6.pdf",
    "assets/module/elementary/Grade 5/Quarter 1/Week 6/Arts Q1 W6.pdf",
    "assets/module/elementary/Grade 5/Quarter 1/Week 6/ESP Q1 W6.pdf",
    "assets/module/elementary/Grade 5/Quarter 1/Week 6/English Q1 W6.pdf",
    "assets/module/elementary/Grade 5/Quarter 1/Week 6/Filipino Q1 W6.pdf",
    "assets/module/elementary/Grade 5/Quarter 1/Week 6/Math Q1 W6.pdf",
    "assets/module/elementary/Grade 5/Quarter 1/Week 6/Science Q1 W6.pdf",
    "assets/module/elementary/Grade 5/Quarter 1/Week 6/TLE Q1 W6.pdf",
    "assets/module/elementary/Grade 5/Quarter 1/Week 5/AP Q1 W5.pdf",
    "assets/module/elementary/Grade 5/Quarter 1/Week 5/ESP Q1 W5.pdf",
    "assets/module/elementary/Grade 5/Quarter 1/Week 5/English Q1 W5.pdf",
    "assets/module/elementary/Grade 5/Quarter 1/Week 5/Filipino Q1 W5.pdf",
    "assets/module/elementary/Grade 5/Quarter 1/Week 5/Math Q1 W5.pdf",
    "assets/module/elementary/Grade 5/Quarter 1/Week 5/Music Q1 W5.pdf",
    "assets/module/elementary/Grade 5/Quarter 1/Week 5/Science Q1 W5.pdf",
    "assets/module/elementary/Grade 5/Quarter 1/Week 5/TLE Q1 W5.pdf",
    "assets/module/elementary/Grade 5/Quarter 1/Week 3/AP Q1 W3.pdf",
    "assets/module/elementary/Grade 5/Quarter 1/Week 3/ESP Q1 W3.pdf",
    "assets/module/elementary/Grade 5/Quarter 1/Week 3/English Q1 W3.pdf",
    "assets/module/elementary/Grade 5/Quarter 1/Week 3/Filipino Q1 W3.pdf",
    "assets/module/elementary/Grade 5/Quarter 1/Week 3/Math Q1 W3.pdf",
    "assets/module/elementary/Grade 5/Quarter 1/Week 3/PE Q1 W3.pdf",
    "assets/module/elementary/Grade 5/Quarter 1/Week 3/Science Q1 W3.pdf",
    "assets/module/elementary/Grade 5/Quarter 1/Week 3/TLE Q1 W3.pdf",
    "assets/module/elementary/Grade 5/Quarter 1/Week 4/AP Q1 W4.pdf",
    "assets/module/elementary/Grade 5/Quarter 1/Week 4/ESP Q1 W4.pdf",
    "assets/module/elementary/Grade 5/Quarter 1/Week 4/English Q1 W4.pdf",
    "assets/module/elementary/Grade 5/Quarter 1/Week 4/Filipino Q1 W4.pdf",
    "assets/module/elementary/Grade 5/Quarter 1/Week 4/Health Q1 W4.pdf",
    "assets/module/elementary/Grade 5/Quarter 1/Week 4/Math Q1 W4.pdf",
    "assets/module/elementary/Grade 5/Quarter 1/Week 4/Science Q1 W4.pdf",
    "assets/module/elementary/Grade 5/Quarter 1/Week 4/TLE Q1 W4.pdf",
    "assets/module/elementary/Grade 5/Quarter 1/Week 7/AP Q1 W7.pdf",
    "assets/module/elementary/Grade 5/Quarter 1/Week 7/ESP Q1 W7.pdf",
    "assets/module/elementary/Grade 5/Quarter 1/Week 7/English Q1 W7.pdf",
    "assets/module/elementary/Grade 5/Quarter 1/Week 7/Filipino Q1 W7.pdf",
    "assets/module/elementary/Grade 5/Quarter 1/Week 7/Math Q1 W7.pdf",
    "assets/module/elementary/Grade 5/Quarter 1/Week 7/PE Q1 W7.pdf",
    "assets/module/elementary/Grade 5/Quarter 1/Week 7/Science Q1 W7.pdf",
    "assets/module/elementary/Grade 5/Quarter 1/Week 7/TLE Q1 W7.pdf",
    "assets/module/elementary/Grade 5/Quarter 1/Week 8/AP Q1 Assessment.pdf",
    "assets/module/elementary/Grade 5/Quarter 1/Week 8/ESP Q1 Assessment.pdf",
    "assets/module/elementary/Grade 5/Quarter 1/Week 8/English Q1 Assessment.pdf",
    "assets/module/elementary/Grade 5/Quarter 1/Week 8/Filipino Q1 Assessment.pdf",
    "assets/module/elementary/Grade 5/Quarter 1/Week 8/MAPEH Q1 Assessment.pdf",
    "assets/module/elementary/Grade 5/Quarter 1/Week 8/Math Q1 Assessment.pdf",
    "assets/module/elementary/Grade 5/Quarter 1/Week 8/Science Q1 Assessment.pdf",
    "assets/module/elementary/Grade 5/Quarter 1/Week 8/TLE Q1 Assessment.pdf",
    "assets/module/elementary/Grade 5/Quarter 2/Week 1/AP Q2 W1.pdf",
    "assets/module/elementary/Grade 5/Quarter 2/Week 1/ESP Q2 W1.pdf",
    "assets/module/elementary/Grade 5/Quarter 2/Week 1/English Q2 W1.pdf",
    "assets/module/elementary/Grade 5/Quarter 2/Week 1/Filipino Q2 W1.pdf",
    "assets/module/elementary/Grade 5/Quarter 2/Week 1/Math Q2 W1.pdf",
    "assets/module/elementary/Grade 5/Quarter 2/Week 1/Music Q2 W1.pdf",
    "assets/module/elementary/Grade 5/Quarter 2/Week 1/Science Q2 W1.pdf",
    "assets/module/elementary/Grade 5/Quarter 2/Week 1/TLE Q2 W1.pdf",
    "assets/module/elementary/Grade 5/Quarter 2/Week 3/AP Q2 W3.pdf",
    "assets/module/elementary/Grade 5/Quarter 2/Week 3/ESP Q2 W3.pdf",
    "assets/module/elementary/Grade 5/Quarter 2/Week 3/English Q2 W3.pdf",
    "assets/module/elementary/Grade 5/Quarter 2/Week 3/Filipino Q2 W3.pdf",
    "assets/module/elementary/Grade 5/Quarter 2/Week 3/Math Q2 W3.pdf",
    "assets/module/elementary/Grade 5/Quarter 2/Week 3/PE Q2 W3.pdf",
    "assets/module/elementary/Grade 5/Quarter 2/Week 3/Science Q2 W3.pdf",
    "assets/module/elementary/Grade 5/Quarter 2/Week 3/TLE Q2 W3.pdf",
    "assets/module/elementary/Grade 5/Quarter 2/Week 4/AP Q2 W4.pdf",
    "assets/module/elementary/Grade 5/Quarter 2/Week 4/ESP Q2 W4.pdf",
    "assets/module/elementary/Grade 5/Quarter 2/Week 4/English Q2 W4.pdf",
    "assets/module/elementary/Grade 5/Quarter 2/Week 4/Filipino Q2 W4.pdf",
    "assets/module/elementary/Grade 5/Quarter 2/Week 4/Health Q2 W4.pdf",
    "assets/module/elementary/Grade 5/Quarter 2/Week 4/Math Q2 W4.pdf",
    "assets/module/elementary/Grade 5/Quarter 2/Week 4/Science Q2 W4.pdf",
    "assets/module/elementary/Grade 5/Quarter 2/Week 4/TLE Q2 W4.pdf",
    "assets/module/elementary/Grade 5/Quarter 2/Week 5/AP Q2 W5.pdf",
    "assets/module/elementary/Grade 5/Quarter 2/Week 5/ESP Q2 W5.pdf",
    "assets/module/elementary/Grade 5/Quarter 2/Week 5/English Q2 W5.pdf",
    "assets/module/elementary/Grade 5/Quarter 2/Week 5/Filipino Q2 W5.pdf",
    "assets/module/elementary/Grade 5/Quarter 2/Week 5/Math Q2 W5.pdf",
    "assets/module/elementary/Grade 5/Quarter 2/Week 5/Music Q2 W5.pdf",
    "assets/module/elementary/Grade 5/Quarter 2/Week 5/Science Q2 W5.pdf",
    "assets/module/elementary/Grade 5/Quarter 2/Week 5/TLE Q2 W5.pdf",
    "assets/module/elementary/Grade 5/Quarter 2/Week 6/AP Q2 W6.pdf",
    "assets/module/elementary/Grade 5/Quarter 2/Week 6/Arts Q2 W6.pdf",
    "assets/module/elementary/Grade 5/Quarter 2/Week 6/ESP Q2 W6.pdf",
    "assets/module/elementary/Grade 5/Quarter 2/Week 6/English Q2 W6.pdf",
    "assets/module/elementary/Grade 5/Quarter 2/Week 6/Filipino Q2 W6.pdf",
    "assets/module/elementary/Grade 5/Quarter 2/Week 6/Math Q2 W6.pdf",
    "assets/module/elementary/Grade 5/Quarter 2/Week 6/Science Q2 W6.pdf",
    "assets/module/elementary/Grade 5/Quarter 2/Week 6/TLE Q2 W6.pdf",
    "assets/module/elementary/Grade 5/Quarter 3/Week 1/AP Q3 W1.pdf",
    "assets/module/elementary/Grade 5/Quarter 3/Week 1/ESP Q3 W1.pdf",
    "assets/module/elementary/Grade 5/Quarter 3/Week 1/English Q3 W1.pdf",
    "assets/module/elementary/Grade 5/Quarter 3/Week 1/Filipino Q3 W1.pdf",
    "assets/module/elementary/Grade 5/Quarter 3/Week 1/Math Q3 W1.pdf",
    "assets/module/elementary/Grade 5/Quarter 3/Week 1/Music Q3 W1.pdf",
    "assets/module/elementary/Grade 5/Quarter 3/Week 1/Science Q3 W1.pdf",
    "assets/module/elementary/Grade 5/Quarter 3/Week 1/TLE Q3 W1.pdf",
    "assets/module/elementary/Grade 5/Quarter 3/Week 2/AP Q3 W2.pdf",
    "assets/module/elementary/Grade 5/Quarter 3/Week 2/Arts Q3 W2.pdf",
    "assets/module/elementary/Grade 5/Quarter 3/Week 2/ESP Q3 W2.pdf",
    "assets/module/elementary/Grade 5/Quarter 3/Week 2/English Q3 W2.pdf",
    "assets/module/elementary/Grade 5/Quarter 3/Week 2/Filipino Q3 W2.pdf",
    "assets/module/elementary/Grade 5/Quarter 3/Week 2/Math Q3 W2.pdf",
    "assets/module/elementary/Grade 5/Quarter 3/Week 2/Science Q3 W2.pdf",
    "assets/module/elementary/Grade 5/Quarter 3/Week 2/TLE Q3 W2.pdf",
    "assets/module/elementary/Grade 5/Quarter 3/Week 3/AP Q3 W3.pdf",
    "assets/module/elementary/Grade 5/Quarter 3/Week 3/ESP Q3 W3.pdf",
    "assets/module/elementary/Grade 5/Quarter 3/Week 3/English Q3 W3.pdf",
    "assets/module/elementary/Grade 5/Quarter 3/Week 3/Filipino Q3 W3.pdf",
    "assets/module/elementary/Grade 5/Quarter 3/Week 3/Math Q3 W3.pdf",
    "assets/module/elementary/Grade 5/Quarter 3/Week 3/PE Q3 W3.pdf",
    "assets/module/elementary/Grade 5/Quarter 3/Week 3/Science Q3 W3.pdf",
    "assets/module/elementary/Grade 5/Quarter 3/Week 3/TLE Q3 W3.pdf",
    "assets/module/elementary/Grade 5/Quarter 3/Week 4/AP Q3 W4.pdf",
    "assets/module/elementary/Grade 5/Quarter 3/Week 4/ESP Q3 W4.pdf",
    "assets/module/elementary/Grade 5/Quarter 3/Week 4/English Q3 W4.pdf",
    "assets/module/elementary/Grade 5/Quarter 3/Week 4/Filipino Q3 W4.pdf",
    "assets/module/elementary/Grade 5/Quarter 3/Week 4/Health Q3 W4.pdf",
    "assets/module/elementary/Grade 5/Quarter 3/Week 4/Math Q3 W4.pdf",
    "assets/module/elementary/Grade 5/Quarter 3/Week 4/Science Q3 W4.pdf",
    "assets/module/elementary/Grade 5/Quarter 3/Week 4/TLE Q3 W4.pdf",
    "assets/module/elementary/Grade 5/Quarter 3/Week 5/AP Q3 W5.pdf",
    "assets/module/elementary/Grade 5/Quarter 3/Week 5/ESP Q3 W5.pdf",
    "assets/module/elementary/Grade 5/Quarter 3/Week 5/English Q3 W5.pdf",
    "assets/module/elementary/Grade 5/Quarter 3/Week 5/Filipino Q3 W5.pdf",
    "assets/module/elementary/Grade 5/Quarter 3/Week 5/Math Q3 W5.pdf",
    "assets/module/elementary/Grade 5/Quarter 3/Week 5/Music Q3 W5.pdf",
    "assets/module/elementary/Grade 5/Quarter 3/Week 5/Science Q3 W5.pdf",
    "assets/module/elementary/Grade 5/Quarter 3/Week 5/TLE Q3 W5.pdf",
    "assets/module/elementary/Grade 5/Quarter 3/Week 6/AP Q3 W6.pdf",
    "assets/module/elementary/Grade 5/Quarter 3/Week 6/Arts Q3 W6.pdf",
    "assets/module/elementary/Grade 5/Quarter 3/Week 6/ESP Q3 W6.pdf",
    "assets/module/elementary/Grade 5/Quarter 3/Week 6/English Q3 W6.pdf",
    "assets/module/elementary/Grade 5/Quarter 3/Week 6/Filipino Q3 W6.pdf",
    "assets/module/elementary/Grade 5/Quarter 3/Week 6/Math Q3 W6.pdf",
    "assets/module/elementary/Grade 5/Quarter 3/Week 6/Science Q3 W6.pdf",
    "assets/module/elementary/Grade 5/Quarter 3/Week 6/TLE Q3 W6.pdf",
    "assets/module/elementary/Grade 5/Quarter 3/Week 7/AP Q3 W7.pdf",
    "assets/module/elementary/Grade 5/Quarter 3/Week 7/ESP Q3 W7.pdf",
    "assets/module/elementary/Grade 5/Quarter 3/Week 7/English Q3 W7.pdf",
    "assets/module/elementary/Grade 5/Quarter 3/Week 7/Filipino Q3 W7.pdf",
    "assets/module/elementary/Grade 5/Quarter 3/Week 7/Math Q3 W7.pdf",
    "assets/module/elementary/Grade 5/Quarter 3/Week 7/PE Q3 W7.pdf",
    "assets/module/elementary/Grade 5/Quarter 3/Week 7/Science Q3 W7.pdf",
    "assets/module/elementary/Grade 5/Quarter 3/Week 7/TLE Q3 W7.pdf",
    "assets/module/elementary/Grade 5/Quarter 4/Week 2/AP Q4 W2.pdf",
    "assets/module/elementary/Grade 5/Quarter 4/Week 2/Arts Q4 W2.pdf",
    "assets/module/elementary/Grade 5/Quarter 4/Week 2/ESP Q4 W2.pdf",
    "assets/module/elementary/Grade 5/Quarter 4/Week 2/English Q4 W2.pdf",
    "assets/module/elementary/Grade 5/Quarter 4/Week 2/Filipino Q4 W2.pdf",
    "assets/module/elementary/Grade 5/Quarter 4/Week 2/Math Q4 W2.pdf",
    "assets/module/elementary/Grade 5/Quarter 4/Week 2/Science Q4 W2.pdf",
    "assets/module/elementary/Grade 5/Quarter 4/Week 2/TLE Q4 W2.pdf",
    "assets/module/elementary/Grade 5/Quarter 4/Week 1/AP Q4 W1.pdf",
    "assets/module/elementary/Grade 5/Quarter 4/Week 1/ESP Q4 W1.pdf",
    "assets/module/elementary/Grade 5/Quarter 4/Week 1/English Q4 W1.pdf",
    "assets/module/elementary/Grade 5/Quarter 4/Week 1/Filipino Q4 W1.pdf",
    "assets/module/elementary/Grade 5/Quarter 4/Week 1/Math Q4 W1.pdf",
    "assets/module/elementary/Grade 5/Quarter 4/Week 1/Music Q4 W1.pdf",
    "assets/module/elementary/Grade 5/Quarter 4/Week 1/Science Q4 W1.pdf",
    "assets/module/elementary/Grade 5/Quarter 4/Week 1/TLE Q4 W1.pdf",
    "assets/module/elementary/Grade 6/Quarter 1/Week 1/Q1_W1_AP6.pdf",
    "assets/module/elementary/Grade 6/Quarter 1/Week 1/Q1_W1_ESP6.pdf",
    "assets/module/elementary/Grade 6/Quarter 1/Week 1/Q1_W1_English6.pdf",
    "assets/module/elementary/Grade 6/Quarter 1/Week 1/Q1_W1_Filipino6.pdf",
    "assets/module/elementary/Grade 6/Quarter 1/Week 1/Q1_W1_MAPEH6.pdf",
    "assets/module/elementary/Grade 6/Quarter 1/Week 1/Q1_W1_Mathematics6.pdf",
    "assets/module/elementary/Grade 6/Quarter 1/Week 1/Q1_W1_Science6.pdf",
    "assets/module/elementary/Grade 6/Quarter 1/Week 1/Q1_W1_TLE6..pdf",
    "assets/module/elementary/Grade 6/Quarter 1/Week 2/Q1_W2_AP6.pdf",
    "assets/module/elementary/Grade 6/Quarter 1/Week 2/Q1_W2_ESP6.pdf",
    "assets/module/elementary/Grade 6/Quarter 1/Week 2/Q1_W2_English6.pdf",
    "assets/module/elementary/Grade 6/Quarter 1/Week 2/Q1_W2_Filipino6 (1).pdf",
    "assets/module/elementary/Grade 6/Quarter 1/Week 2/Q1_W2_MAPEH6.pdf",
    "assets/module/elementary/Grade 6/Quarter 1/Week 2/Q1_W2_Mathematics6.pdf",
    "assets/module/elementary/Grade 6/Quarter 1/Week 2/Q1_W2_Science6.pdf",
    "assets/module/elementary/Grade 6/Quarter 1/Week 2/Q1_W2_TLE6.pdf",
    "assets/module/elementary/Grade 6/Quarter 1/Week 5/Q1_W5_AP6.pdf",
    "assets/module/elementary/Grade 6/Quarter 1/Week 5/Q1_W5_ESP6.pdf",
    "assets/module/elementary/Grade 6/Quarter 1/Week 5/Q1_W5_English6.pdf",
    "assets/module/elementary/Grade 6/Quarter 1/Week 5/Q1_W5_Filipino6.pdf",
    "assets/module/elementary/Grade 6/Quarter 1/Week 5/Q1_W5_MAPEH6.pdf",
    "assets/module/elementary/Grade 6/Quarter 1/Week 5/Q1_W5_Mathematics6.pdf",
    "assets/module/elementary/Grade 6/Quarter 1/Week 5/Q1_W5_Science6.pdf",
    "assets/module/elementary/Grade 6/Quarter 1/Week 5/Q1_W5_TLE6.pdf",
    "assets/module/elementary/Grade 6/Quarter 1/Week 3/Q1_W3_AP6.pdf",
    "assets/module/elementary/Grade 6/Quarter 1/Week 3/Q1_W3_ESP6.pdf",
    "assets/module/elementary/Grade 6/Quarter 1/Week 3/Q1_W3_English6.pdf",
    "assets/module/elementary/Grade 6/Quarter 1/Week 3/Q1_W3_Filipino6.pdf",
    "assets/module/elementary/Grade 6/Quarter 1/Week 3/Q1_W3_MAPEH6.pdf",
    "assets/module/elementary/Grade 6/Quarter 1/Week 3/Q1_W3_Mathematics6.pdf",
    "assets/module/elementary/Grade 6/Quarter 1/Week 3/Q1_W3_Science6.pdf",
    "assets/module/elementary/Grade 6/Quarter 1/Week 3/Q1_W3_TLE6.pdf",
    "assets/module/elementary/Grade 6/Quarter 1/Week 6/Q1_W6_AP6.pdf",
    "assets/module/elementary/Grade 6/Quarter 1/Week 6/Q1_W6_ESP6.pdf",
    "assets/module/elementary/Grade 6/Quarter 1/Week 6/Q1_W6_English6..pdf",
    "assets/module/elementary/Grade 6/Quarter 1/Week 6/Q1_W6_Filipino6.pdf",
    "assets/module/elementary/Grade 6/Quarter 1/Week 6/Q1_W6_MAPEH6.pdf",
    "assets/module/elementary/Grade 6/Quarter 1/Week 6/Q1_W6_Mathematics6.pdf",
    "assets/module/elementary/Grade 6/Quarter 1/Week 6/Q1_W6_Science6.pdf",
    "assets/module/elementary/Grade 6/Quarter 1/Week 6/Q1_W6_TLE6.pdf",
    "assets/module/elementary/Grade 6/Quarter 1/Week 4/Q1_W4_AP6.pdf",
    "assets/module/elementary/Grade 6/Quarter 1/Week 4/Q1_W4_English6.pdf",
    "assets/module/elementary/Grade 6/Quarter 1/Week 4/Q1_W4_Filipino6.pdf",
    "assets/module/elementary/Grade 6/Quarter 1/Week 4/Q1_W4_MAPEH6.pdf",
    "assets/module/elementary/Grade 6/Quarter 1/Week 4/Q1_W4_Mathematics6.pdf",
    "assets/module/elementary/Grade 6/Quarter 1/Week 4/Q1_W4_Science6.pdf",
    "assets/module/elementary/Grade 6/Quarter 1/Week 4/Q1_W4_TLE6.pdf",
    "assets/module/elementary/Grade 6/Quarter 1/Week 4/Q4_W4_ESP6.pdf",
    "assets/module/elementary/Grade 6/Quarter 1/Week 7/Q1_W7_AP6.pdf",
    "assets/module/elementary/Grade 6/Quarter 1/Week 7/Q1_W7_ESP6.pdf",
    "assets/module/elementary/Grade 6/Quarter 1/Week 7/Q1_W7_English6.pdf",
    "assets/module/elementary/Grade 6/Quarter 1/Week 7/Q1_W7_Filipino6.pdf",
    "assets/module/elementary/Grade 6/Quarter 1/Week 7/Q1_W7_MAPEH6.pdf",
    "assets/module/elementary/Grade 6/Quarter 1/Week 7/Q1_W7_Mathematics6.pdf",
    "assets/module/elementary/Grade 6/Quarter 1/Week 7/Q1_W7_Science6.pdf",
    "assets/module/elementary/Grade 6/Quarter 1/Week 7/Q1_W7_TLE6.pdf",
    "assets/module/elementary/Grade 6/Quarter 1/Week 8/Q1_W8_ESP6.pdf",
    "assets/module/elementary/Grade 6/Quarter 1/Week 8/Q1_W8_Filipino6.pdf",
    "assets/module/elementary/Grade 6/Quarter 1/Week 8/Q1_W8_MAPEH6.pdf",
    "assets/module/elementary/Grade 6/Quarter 1/Week 8/Q1_W8_Mathematics6.pdf",
    "assets/module/elementary/Grade 6/Quarter 1/Week 8/Q1_W8_Science6.pdf",
    "assets/module/elementary/Grade 6/Quarter 1/Week 8/Q1_W8_TLE6.pdf",
    "assets/module/elementary/Grade 6/Quarter 2/Week 1/Q2_W1_Araling_Panlipunan6.pdf",
    "assets/module/elementary/Grade 6/Quarter 2/Week 1/Q2_W1_ESP6.pdf",
    "assets/module/elementary/Grade 6/Quarter 2/Week 1/Q2_W1_English6.pdf",
    "assets/module/elementary/Grade 6/Quarter 2/Week 1/Q2_W1_Filipino6.pdf",
    "assets/module/elementary/Grade 6/Quarter 2/Week 1/Q2_W1_MAPEH6.pdf",
    "assets/module/elementary/Grade 6/Quarter 2/Week 1/Q2_W1_Mathematics6.pdf",
    "assets/module/elementary/Grade 6/Quarter 2/Week 1/Q2_W1_Science6.pdf",
    "assets/module/elementary/Grade 6/Quarter 2/Week 1/Q2_W1_TLE6.pdf",
    "assets/module/elementary/Grade 6/Quarter 2/Week 3/Q2_W3_Araling_Panlipunan6.pdf",
    "assets/module/elementary/Grade 6/Quarter 2/Week 3/Q2_W3_ESP6.pdf",
    "assets/module/elementary/Grade 6/Quarter 2/Week 3/Q2_W3_English6.pdf",
    "assets/module/elementary/Grade 6/Quarter 2/Week 3/Q2_W3_Filipino6.pdf",
    "assets/module/elementary/Grade 6/Quarter 2/Week 3/Q2_W3_MAPEH6.pdf",
    "assets/module/elementary/Grade 6/Quarter 2/Week 3/Q2_W3_Mathematics6.pdf",
    "assets/module/elementary/Grade 6/Quarter 2/Week 3/Q2_W3_Science6.pdf",
    "assets/module/elementary/Grade 6/Quarter 2/Week 3/Q2_W3_TLE6.pdf",
    "assets/module/elementary/Grade 6/Quarter 2/Week 2/Q2_W2_Araling_Panlipunan6.pdf",
    "assets/module/elementary/Grade 6/Quarter 2/Week 2/Q2_W2_ESP6.pdf",
    "assets/module/elementary/Grade 6/Quarter 2/Week 2/Q2_W2_English6.pdf",
    "assets/module/elementary/Grade 6/Quarter 2/Week 2/Q2_W2_Filipino6.pdf",
    "assets/module/elementary/Grade 6/Quarter 2/Week 2/Q2_W2_MAPEH6.pdf",
    "assets/module/elementary/Grade 6/Quarter 2/Week 2/Q2_W2_Mathematics6.pdf",
    "assets/module/elementary/Grade 6/Quarter 2/Week 2/Q2_W2_Science6.pdf",
    "assets/module/elementary/Grade 6/Quarter 2/Week 2/Q2_W2_TLE6.pdf",
    "assets/module/elementary/Grade 6/Quarter 2/Week 4/Q2_W4-5_Science6.pdf",
    "assets/module/elementary/Grade 6/Quarter 2/Week 4/Q2_W4_Araling_Panlipunan6.pdf",
    "assets/module/elementary/Grade 6/Quarter 2/Week 4/Q2_W4_ESP6.pdf",
    "assets/module/elementary/Grade 6/Quarter 2/Week 4/Q2_W4_English6.pdf",
    "assets/module/elementary/Grade 6/Quarter 2/Week 4/Q2_W4_Filipino6.pdf",
    "assets/module/elementary/Grade 6/Quarter 2/Week 4/Q2_W4_MAPEH6.pdf",
    "assets/module/elementary/Grade 6/Quarter 2/Week 4/Q2_W4_Mathematics6.pdf",
    "assets/module/elementary/Grade 6/Quarter 2/Week 4/Q2_W4_TLE6.pdf",
    "assets/module/elementary/Grade 6/Quarter 2/Week 5/Q2_W5_Araling_Panlipunan6.pdf",
    "assets/module/elementary/Grade 6/Quarter 2/Week 5/Q2_W5_ESP6.pdf",
    "assets/module/elementary/Grade 6/Quarter 2/Week 5/Q2_W5_English6.pdf",
    "assets/module/elementary/Grade 6/Quarter 2/Week 5/Q2_W5_Filipino6.pdf",
    "assets/module/elementary/Grade 6/Quarter 2/Week 5/Q2_W5_MAPEH6.pdf",
    "assets/module/elementary/Grade 6/Quarter 2/Week 5/Q2_W5_Mathematics6.pdf",
    "assets/module/elementary/Grade 6/Quarter 2/Week 5/Q2_W5_TLE6.pdf",
    "assets/module/elementary/Grade 6/Quarter 2/Week 6/Q2_W6_Araling_Panlipunan6.pdf",
    "assets/module/elementary/Grade 6/Quarter 2/Week 6/Q2_W6_English6.pdf",
    "assets/module/elementary/Grade 6/Quarter 2/Week 6/Q2_W6_Filipino6.pdf",
    "assets/module/elementary/Grade 6/Quarter 2/Week 6/Q2_W6_MAPEH6.pdf",
    "assets/module/elementary/Grade 6/Quarter 2/Week 6/Q2_W6_Mathematics6.pdf",
    "assets/module/elementary/Grade 6/Quarter 2/Week 6/Q2_W6_Science6.pdf",
    "assets/module/elementary/Grade 6/Quarter 2/Week 6/Q2_W6_TLE6.pdf",
    "assets/module/elementary/Grade 6/Quarter 2/Week 6/Q2_W6_WSP6.pdf",
    "assets/module/elementary/Grade 6/Quarter 2/Week 7/Q2_W7_Araling_Panlipunan6.pdf",
    "assets/module/elementary/Grade 6/Quarter 2/Week 7/Q2_W7_ESP6.pdf",
    "assets/module/elementary/Grade 6/Quarter 2/Week 7/Q2_W7_English6.pdf",
    "assets/module/elementary/Grade 6/Quarter 2/Week 7/Q2_W7_Filipino6.pdf",
    "assets/module/elementary/Grade 6/Quarter 2/Week 7/Q2_W7_MAPEH6.pdf",
    "assets/module/elementary/Grade 6/Quarter 2/Week 7/Q2_W7_Mathematics6.pdf",
    "assets/module/elementary/Grade 6/Quarter 2/Week 7/Q2_W7_Science6.pdf",
    "assets/module/elementary/Grade 6/Quarter 2/Week 8/Q2_W8_Araling_Panlipunan6.pdf",
    "assets/module/elementary/Grade 6/Quarter 2/Week 8/Q2_W8_ESP6.pdf",
    "assets/module/elementary/Grade 6/Quarter 2/Week 8/Q2_W8_Filipino6.pdf",
    "assets/module/elementary/Grade 6/Quarter 2/Week 8/Q2_W8_MAPEH6.pdf",
    "assets/module/elementary/Grade 6/Quarter 2/Week 8/Q2_W8_Mathematics6.pdf",
    "assets/module/elementary/Grade 6/Quarter 3/Week 2/Q2_W2_AP6.pdf",
    "assets/module/elementary/Grade 6/Quarter 3/Week 2/Q3_W2_English6.pdf",
    "assets/module/elementary/Grade 6/Quarter 3/Week 2/Q3_W2_Esp6.pdf",
    "assets/module/elementary/Grade 6/Quarter 3/Week 2/Q3_W2_Filipino6.pdf",
    "assets/module/elementary/Grade 6/Quarter 3/Week 2/Q3_W2_MAPEH6 (1).pdf",
    "assets/module/elementary/Grade 6/Quarter 3/Week 2/Q3_W2_Math6.pdf",
    "assets/module/elementary/Grade 6/Quarter 3/Week 2/Q3_W2_Science6.pdf",
    "assets/module/elementary/Grade 6/Quarter 3/Week 2/Q3_W2_TLE6.pdf",
    "assets/module/elementary/Grade 6/Quarter 3/Week 1/Q3_W1_AP6.pdf",
    "assets/module/elementary/Grade 6/Quarter 3/Week 1/Q3_W1_English6.pdf",
    "assets/module/elementary/Grade 6/Quarter 3/Week 1/Q3_W1_Esp6.pdf",
    "assets/module/elementary/Grade 6/Quarter 3/Week 1/Q3_W1_Filipino6.pdf",
    "assets/module/elementary/Grade 6/Quarter 3/Week 1/Q3_W1_MAPEH6.pdf",
    "assets/module/elementary/Grade 6/Quarter 3/Week 1/Q3_W1_Mathematics6.pdf",
    "assets/module/elementary/Grade 6/Quarter 3/Week 1/Q3_W1_Science6.pdf",
    "assets/module/elementary/Grade 6/Quarter 3/Week 1/Q3_W1_TLE6.pdf",
    "assets/module/elementary/Grade 6/Quarter 3/Week 5/Q3_W5_AP6.pdf",
    "assets/module/elementary/Grade 6/Quarter 3/Week 5/Q3_W5_ESP6.pdf",
    "assets/module/elementary/Grade 6/Quarter 3/Week 5/Q3_W5_English6.pdf",
    "assets/module/elementary/Grade 6/Quarter 3/Week 5/Q3_W5_Filipino6.pdf",
    "assets/module/elementary/Grade 6/Quarter 3/Week 5/Q3_W5_MAPEH6.pdf",
    "assets/module/elementary/Grade 6/Quarter 3/Week 5/Q3_W5_Math6.pdf",
    "assets/module/elementary/Grade 6/Quarter 3/Week 5/Q3_W5_Science6.pdf",
    "assets/module/elementary/Grade 6/Quarter 3/Week 5/Q3_W5_TLE6.pdf",
    "assets/module/elementary/Grade 6/Quarter 3/Week 4/Q3_W4_AP6.pdf",
    "assets/module/elementary/Grade 6/Quarter 3/Week 4/Q3_W4_ESP6.pdf",
    "assets/module/elementary/Grade 6/Quarter 3/Week 4/Q3_W4_English6.pdf",
    "assets/module/elementary/Grade 6/Quarter 3/Week 4/Q3_W4_Filipino6.pdf",
    "assets/module/elementary/Grade 6/Quarter 3/Week 4/Q3_W4_MAPEH6.pdf",
    "assets/module/elementary/Grade 6/Quarter 3/Week 4/Q3_W4_Math6.pdf",
    "assets/module/elementary/Grade 6/Quarter 3/Week 4/Q3_W4_Science6.pdf",
    "assets/module/elementary/Grade 6/Quarter 3/Week 4/Q3_W4_TLE6.pdf",
    "assets/module/elementary/Grade 6/Quarter 3/Week 3/Q3_W3_AralingPanlipunan6.pdf",
    "assets/module/elementary/Grade 6/Quarter 3/Week 3/Q3_W3_ESP6.pdf",
    "assets/module/elementary/Grade 6/Quarter 3/Week 3/Q3_W3_English6.pdf",
    "assets/module/elementary/Grade 6/Quarter 3/Week 3/Q3_W3_Filipino6.pdf",
    "assets/module/elementary/Grade 6/Quarter 3/Week 3/Q3_W3_MAPEH6.pdf",
    "assets/module/elementary/Grade 6/Quarter 3/Week 3/Q3_W3_Mathematics6.pdf",
    "assets/module/elementary/Grade 6/Quarter 3/Week 3/Q3_W3_Science6.pdf",
    "assets/module/elementary/Grade 6/Quarter 3/Week 3/Q3_W3_TLE6.pdf",
    "assets/module/elementary/Grade 6/Quarter 3/Week 6/Q3_W6_AP6.pdf",
    "assets/module/elementary/Grade 6/Quarter 3/Week 6/Q3_W6_ESP6.pdf",
    "assets/module/elementary/Grade 6/Quarter 3/Week 6/Q3_W6_English6.pdf",
    "assets/module/elementary/Grade 6/Quarter 3/Week 6/Q3_W6_Filipino6.docx",
    "assets/module/elementary/Grade 6/Quarter 3/Week 6/Q3_W6_Filipino6.pdf",
    "assets/module/elementary/Grade 6/Quarter 3/Week 6/Q3_W6_MAPEH6.pdf",
    "assets/module/elementary/Grade 6/Quarter 3/Week 6/Q3_W6_Math6.pdf",
    "assets/module/elementary/Grade 6/Quarter 3/Week 6/Q3_W6_Science6.pdf",
    "assets/module/elementary/Grade 6/Quarter 3/Week 6/Q3_W6_TLE6.pdf",
    "assets/module/elementary/Grade 6/Quarter 4/Week 1/Q4_W1_AP6.pdf",
    "assets/module/elementary/Grade 6/Quarter 4/Week 1/Q4_W1_ESP6.pdf",
    "assets/module/elementary/Grade 6/Quarter 4/Week 1/Q4_W1_Filipino6.pdf",
    "assets/module/elementary/Grade 6/Quarter 4/Week 1/Q4_W1_MAPEH6.pdf",
    "assets/module/elementary/Grade 6/Quarter 4/Week 1/Q4_W1_Math6.pdf",
    "assets/module/elementary/Grade 6/Quarter 4/Week 1/Q4_W1_Science6.pdf",
    "assets/module/elementary/Grade 6/Quarter 4/Week 1/Q4_W1_TLE6.pdf",
    "assets/module/elementary/Grade 6/Quarter 4/Week 1/Q4_Week_1_English6.pdf",
    "assets/module/elementary/Grade 6/Quarter 4/Week 3/Q4_W3_AP6.pdf",
    "assets/module/elementary/Grade 6/Quarter 4/Week 3/Q4_W3_ESP6.pdf",
    "assets/module/elementary/Grade 6/Quarter 4/Week 3/Q4_W3_Filipino6.pdf",
    "assets/module/elementary/Grade 6/Quarter 4/Week 3/Q4_W3_MAPEH6.pdf",
    "assets/module/elementary/Grade 6/Quarter 4/Week 3/Q4_W3_Math6.pdf",
    "assets/module/elementary/Grade 6/Quarter 4/Week 3/Q4_W3_Science6.pdf",
    "assets/module/elementary/Grade 6/Quarter 4/Week 3/Q4_W3_TLE6.pdf",
    "assets/module/elementary/Grade 6/Quarter 4/Week 3/Q4_Week_3_English6.pdf",
    "assets/module/elementary/Grade 6/Quarter 4/Week 4/Q4_W4_AP6.pdf",
    "assets/module/elementary/Grade 6/Quarter 4/Week 4/Q4_W4_ESP6.pdf",
    "assets/module/elementary/Grade 6/Quarter 4/Week 4/Q4_W4_English6.pdf",
    "assets/module/elementary/Grade 6/Quarter 4/Week 4/Q4_W4_Filipino6.pdf",
    "assets/module/elementary/Grade 6/Quarter 4/Week 4/Q4_W4_MAPEH6.pdf",
    "assets/module/elementary/Grade 6/Quarter 4/Week 4/Q4_W4_Math6.pdf",
    "assets/module/elementary/Grade 6/Quarter 4/Week 4/Q4_W4_Science6.pdf",
    "assets/module/elementary/Grade 6/Quarter 4/Week 4/Q4_W4_TLE6.pdf",
    "assets/module/elementary/Grade 6/Quarter 4/Week 2/Q4_W2_AP6.pdf",
    "assets/module/elementary/Grade 6/Quarter 4/Week 2/Q4_W2_ESP6.pdf",
    "assets/module/elementary/Grade 6/Quarter 4/Week 2/Q4_W2_Filipino6.pdf",
    "assets/module/elementary/Grade 6/Quarter 4/Week 2/Q4_W2_MAPEH6.pdf",
    "assets/module/elementary/Grade 6/Quarter 4/Week 2/Q4_W2_Math6.pdf",
    "assets/module/elementary/Grade 6/Quarter 4/Week 2/Q4_W2_Science4.pdf",
    "assets/module/elementary/Grade 6/Quarter 4/Week 2/Q4_W2_TLE6.pdf",
    "assets/module/elementary/Grade 6/Quarter 4/Week 2/Q4_Week_2_English6.pdf",
    "assets/module/elementary/Grade 6/Quarter 3/Week 7/Q3_W7_AP6.pdf",
    "assets/module/elementary/Grade 6/Quarter 3/Week 7/Q3_W7_ESP6.docx",
    "assets/module/elementary/Grade 6/Quarter 3/Week 7/Q3_W7_ESP6.pdf",
    "assets/module/elementary/Grade 6/Quarter 3/Week 7/Q3_W7_English6.pdf",
    "assets/module/elementary/Grade 6/Quarter 3/Week 7/Q3_W7_MAPEH6.pdf",
    "assets/module/elementary/Grade 6/Quarter 3/Week 7/Q3_W7_Mathematics6.pdf",
    "assets/module/elementary/Grade 6/Quarter 3/Week 7/Q3_W7_Science6.pdf",
    "assets/module/elementary/Grade 6/Quarter 3/Week 7/Q3_W7_TLE6.pdf","assets/module/elementary/Kinder-Nursery/QUARTER 1/WEEK 1-2/ARALING PANLIPUNAN-MODULE 1(1).pdf",
    "assets/module/elementary/Kinder-Nursery/QUARTER 1/WEEK 1-2/ARALING PANLIPUNAN-MODULE 1.pdf",
    "assets/module/elementary/Kinder-Nursery/QUARTER 1/WEEK 1-2/ENGLISH -  MODULE 1.pdf",
    "assets/module/elementary/Kinder-Nursery/QUARTER 1/WEEK 1-2/FILIPINO-MODULE FIRST QUARTER.pdf",
    "assets/module/elementary/Kinder-Nursery/QUARTER 1/WEEK 1-2/MATH-MODULE 1.pdf",
    "assets/module/elementary/Kinder-Nursery/QUARTER 1/WEEK 1-2/MTB-MLE MODULE 1ST QUARTER.pdf",
    "assets/module/elementary/Kinder-Nursery/QUARTER 1/WEEK 1-2/SCIENCE - MODULE 1.pdf",
    "assets/module/elementary/Kinder-Nursery/QUARTER 1/WEEK 1-2/TRACE-JHADEN.pdf",
    "assets/module/elementary/Kinder-Nursery/QUARTER 1/WEEK 1-2/TRACE-LAURRAINE.pdf",
    "assets/module/elementary/Kinder-Nursery/QUARTER 1/WEEK 1-2/TRACE-MATTHEWS.pdf",
    "assets/module/elementary/Kinder-Nursery/QUARTER 1/WEEK 1-2/TRACE-PAULE.pdf",
    "assets/module/elementary/Kinder-Nursery/QUARTER 1/WEEK 1-2/TRACING WORDS.pdf",
    "assets/module/elementary/Kinder-Nursery/QUARTER 1/WEEK 3-4/ENGLISH -  MODULE 2.pdf",
    "assets/module/elementary/Kinder-Nursery/QUARTER 1/WEEK 3-4/FILIPINO-MODULE FIRST QUARTER.pdf",
    "assets/module/elementary/Kinder-Nursery/QUARTER 1/WEEK 3-4/MAPEH-MODULE 1.pdf",
    "assets/module/elementary/Kinder-Nursery/QUARTER 1/WEEK 3-4/MATH-MODULE 1.pdf",
    "assets/module/elementary/Kinder-Nursery/QUARTER 1/WEEK 3-4/MTB-MLE MODULE 1ST QUARTER.pdf",
    "assets/module/elementary/Kinder-Nursery/QUARTER 1/WEEK 3-4/SCIENCE-MODULE 2.pdf",
    "assets/module/elementary/Kinder-Nursery/QUARTER 1/WEEK 3-4/TRACING WORDS2.pdf",
    "assets/module/elementary/Kinder-Nursery/QUARTER 1/WEEK 5-6/ARALING PANLIPUNAN-MODULE 3.pdf",
    "assets/module/elementary/Kinder-Nursery/QUARTER 1/WEEK 5-6/ENGLISH-MODULE 3.pdf",
    "assets/module/elementary/Kinder-Nursery/QUARTER 1/WEEK 5-6/MAPEH-MODULE 3.pdf",
    "assets/module/elementary/Kinder-Nursery/QUARTER 1/WEEK 5-6/MATH-MODULE 3.pdf",
    "assets/module/elementary/Kinder-Nursery/QUARTER 1/WEEK 5-6/SCIENCE-MODULE 3.pdf",
    "assets/module/elementary/Kinder-Nursery/QUARTER 1/WEEK 7-8/ARALING PANLIPUNAN-MODULE 4.pdf",
    "assets/module/elementary/Kinder-Nursery/QUARTER 1/WEEK 7-8/ENGLISH-MODULE 4.pdf",
    "assets/module/elementary/Kinder-Nursery/QUARTER 1/WEEK 7-8/FILIPINO-MODULE FIRST QUARTER.pdf",
    "assets/module/elementary/Kinder-Nursery/QUARTER 1/WEEK 7-8/MAPEH-MODULE 4.pdf",
    "assets/module/elementary/Kinder-Nursery/QUARTER 1/WEEK 7-8/MATH-MODULE 4.pdf",
    "assets/module/elementary/Kinder-Nursery/QUARTER 1/WEEK 7-8/SCIENCE-MODULE 4.pdf",
    "assets/module/elementary/Kinder-Nursery/QUARTER 2/WEEK 1-2/ARALING PANLIPUNAN-MODULE 1.pdf",
    "assets/module/elementary/Kinder-Nursery/QUARTER 2/WEEK 1-2/ENGLISH - MODULE 1.pdf",
    "assets/module/elementary/Kinder-Nursery/QUARTER 2/WEEK 1-2/FILIPINO - MODULE 1.pdf",
    "assets/module/elementary/Kinder-Nursery/QUARTER 2/WEEK 1-2/MATH-MODULE 1.pdf",
    "assets/module/elementary/Kinder-Nursery/QUARTER 2/WEEK 1-2/MTB-MLE - MODULE 1.pdf",
    "assets/module/elementary/Kinder-Nursery/QUARTER 2/WEEK 1-2/SCIENCE - MODULE 1.pdf",
    "assets/module/elementary/Kinder-Nursery/QUARTER 2/WEEK 3/ENGLISH - MODULE 2.pdf",
    "assets/module/elementary/Kinder-Nursery/QUARTER 2/WEEK 3/MATH-MODULE 2.pdf",
    "assets/module/elementary/Kinder-Nursery/QUARTER 2/WEEK 3/SCIENCE - MODULE 2.pdf"
]

export const FilesSenior = [
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/Earth and Life Science/ELS Removal Activity.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/Earth and Life Science/ELS Removal Exam.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/Earth and Life Science/ELS_Curriculum TImeline.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/Earth and Life Science/ES Removal Activity.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/Earth and Life Science/ES Removal Exam.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/Earth and Life Science/Quarter 1/ELS_Q1_Mod1.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/Earth and Life Science/Quarter 1/ELS_Q1_Mod2.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/Earth and Life Science/Quarter 1/ELS_Q1_Mod3.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/Earth and Life Science/Quarter 1/ELS_Q1_Mod4.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/Earth and Life Science/Quarter 1/ELS_Q1_Mod5.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/Earth and Life Science/Quarter 1/ELS_Q1_Mod6.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/Earth and Life Science/Quarter 2/ELS_Q2_Mod1.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/Earth and Life Science/Quarter 2/ELS_Q2_Mod2.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/Earth and Life Science/Quarter 2/ELS_Q2_Mod3.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/Earth and Life Science/Quarter 2/ELS_Q2_Mod4.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/General Mathematics/General mathematics Module-EBOOK.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/General Mathematics/Worksheets_Unit_1_Week_1_General-Mathematics.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/General Mathematics/Worksheets_Unit_1_Week_2_General-Mathematics.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/General Mathematics/Worksheets_Unit_1_Week_3-4_General-Mathematics.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/General Mathematics/Worksheets_Unit_1_Week_5-6_General-Mathematics.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/General Mathematics/Worksheets_Unit_1_Week_7-8_General-Mathematics.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/General Mathematics/Worksheets_Unit_2_Week_11_General-Mathematics.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/General Mathematics/Worksheets_Unit_2_Week_12_General-Mathematics.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/General Mathematics/Worksheets_Unit_2_Week_13-14_General-Mathematics.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/General Mathematics/Worksheets_Unit_2_Week_15-16_General-Mathematics.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/General Mathematics/Worksheets_Unit_2_Week_9-10_General-Mathematics.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/Komunikasyon at Pananaliksik sa Wika at Kulturang Pilipino/Komunikasyon at Pananaliksik.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/Komunikasyon at Pananaliksik sa Wika at Kulturang Pilipino/Quarter 1/Kom Q1 M1.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/Komunikasyon at Pananaliksik sa Wika at Kulturang Pilipino/Quarter 1/Kom Q1 M2.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/Komunikasyon at Pananaliksik sa Wika at Kulturang Pilipino/Quarter 1/Kom Q1 M3.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/Komunikasyon at Pananaliksik sa Wika at Kulturang Pilipino/Quarter 1/Kom Q1 M4.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/Komunikasyon at Pananaliksik sa Wika at Kulturang Pilipino/Quarter 1/Kom Q1 M5.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/Komunikasyon at Pananaliksik sa Wika at Kulturang Pilipino/Quarter 1/Kom Q1 M6.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/Komunikasyon at Pananaliksik sa Wika at Kulturang Pilipino/Quarter 1/Kom Q1 M7.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/Komunikasyon at Pananaliksik sa Wika at Kulturang Pilipino/Quarter 1/Kom Q1 M8.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/Komunikasyon at Pananaliksik sa Wika at Kulturang Pilipino/Quarter 2/Kom Q2 W1.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/Komunikasyon at Pananaliksik sa Wika at Kulturang Pilipino/Quarter 2/Kom Q2 W2.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/Komunikasyon at Pananaliksik sa Wika at Kulturang Pilipino/Quarter 2/Kom Q2 W3.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/Komunikasyon at Pananaliksik sa Wika at Kulturang Pilipino/Quarter 2/Kom Q2 W4.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/Komunikasyon at Pananaliksik sa Wika at Kulturang Pilipino/Quarter 2/Kom Q2 W5.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/Komunikasyon at Pananaliksik sa Wika at Kulturang Pilipino/Quarter 2/Kom Q2 W6.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/Komunikasyon at Pananaliksik sa Wika at Kulturang Pilipino/Quarter 2/Kom Q2 W7.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/Media _ Information Literacy/Lesson 1.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/Media _ Information Literacy/Lesson 2.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/Media _ Information Literacy/Lesson 3.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/Media _ Information Literacy/Lesson 4.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/Media _ Information Literacy/Lesson 5.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/Media _ Information Literacy/Lesson 6.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/Media _ Information Literacy/Lesson 7.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/Media _ Information Literacy/Lesson 8.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/Media _ Information Literacy/Lesson 9.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/PHYSICAL EDUCATION _ HEALTH 1 (H.O.P.E. 1)/PE AND HEALTH 1_MODULE_1_WEEK_1.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/PHYSICAL EDUCATION _ HEALTH 1 (H.O.P.E. 1)/PE AND HEALTH 1_MODULE_2_WEEK_2.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/PHYSICAL EDUCATION _ HEALTH 1 (H.O.P.E. 1)/PE AND HEALTH 1_MODULE_3_WEEK_3.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/PHYSICAL EDUCATION _ HEALTH 1 (H.O.P.E. 1)/PE AND HEALTH 1_MODULE_4_WEEK_4.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/PHYSICAL EDUCATION _ HEALTH 1 (H.O.P.E. 1)/PE AND HEALTH 1_MODULE_5_WEEK_5.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/PHYSICAL EDUCATION _ HEALTH 1 (H.O.P.E. 1)/PE AND HEALTH 1_MODULE_6_WEEK_6.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/PHYSICAL EDUCATION _ HEALTH 1 (H.O.P.E. 1)/PE AND HEALTH 1_MODULE_7_WEEK_7.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/PHYSICAL EDUCATION _ HEALTH 1 (H.O.P.E. 1)/PE AND HEALTH 1_MODULE_8_WEEK_8.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/PHYSICAL EDUCATION _ HEALTH 1 (H.O.P.E. 1)/PE AND HEALTH 1_MODULE_9_WEEK_9.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/Practical Research/Worksheets_Unit_1_Week_1_Practical-Research-1.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/Practical Research/Worksheets_Unit_1_Week_2_Practical-Research-1.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/Practical Research/Worksheets_Unit_1_Week_3_Practical-Research-1.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/Practical Research/Worksheets_Unit_1_Week_4_Practical-Research-1.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/Practical Research/Worksheets_Unit_1_Week_5_Practical-Research-1.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/Practical Research/Worksheets_Unit_1_Week_6_Practical-Research-1.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/Practical Research/Worksheets_Unit_1_Week_7_Practical-Research-1.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/Practical Research/Worksheets_Unit_1_Week_8_Practical-Research-1.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/Reading and Writing/Practical Research 1/Research 2 LM1.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/Reading and Writing/Reading and Writing E-book.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/Statistics and Probability/Worksheets_Unit_1_Week_1_Statistics-and-Probability.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/Statistics and Probability/Worksheets_Unit_1_Week_2_Statistics-and-Probability.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/Statistics and Probability/Worksheets_Unit_1_Week_3_Statistics-and-Probability.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/Statistics and Probability/Worksheets_Unit_1_Week_4_Statistics-and-Probability.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/Statistics and Probability/Worksheets_Unit_1_Week_5_Statistics-and-Probability.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/Statistics and Probability/Worksheets_Unit_1_Week_6_Statistics-and-Probability.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/Statistics and Probability/Worksheets_Unit_1_Week_7_Statistics-and-Probability.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/Statistics and Probability/Worksheets_Unit_1_Week_8_Statistics-and-Probability.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/Statistics and Probability/Worksheets_Unit_2_Week_10_Statistics-and-Probability.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/Statistics and Probability/Worksheets_Unit_2_Week_11_Statistics-and-Probability.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Core Subjects/Statistics and Probability/Worksheets_Unit_2_Week_9_Statistics-and-Probability.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/ABM/FABM1/FUNDAMENTALS OF ABM 1 Q3-MODULE 3-ISALA.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/ABM/FABM1/FUNDAMENTALS OF ABM 1 Q3-MODULE 5-ISALA.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/ABM/FABM1/FUNDAMENTALS OF ABM 1 Q3-W2-Module 2-ISALA (1).pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/ABM/FABM1/FUNDAMENTALS OF ABM 1-Q3-W1-Module 1-ISALA.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/ABM/ORGMAN/ORGMAN Q1M1.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/ABM/ORGMAN/ORGMAN Q1M2.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/ABM/ORGMAN/ORGMAN Q1M3.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/ABM/ORGMAN/ORGMAN Q1M4.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/ABM/ORGMAN/ORGMAN Q1M5.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/ARTS AND DESIGN/Creative Industries 1/Creative Industries 1.docx",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/ARTS AND DESIGN/PHYSICAL _ PERSONAL DEVELOPMENT IN THE ARTS/Q1_A_D_PPDA_WK1_MOD1.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/ARTS AND DESIGN/PHYSICAL _ PERSONAL DEVELOPMENT IN THE ARTS/Q1_A_D_PPDA_WK2_MOD2.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/ARTS AND DESIGN/PHYSICAL _ PERSONAL DEVELOPMENT IN THE ARTS/Q1_A_D_PPDA_WK3_MOD3.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/ARTS AND DESIGN/PHYSICAL _ PERSONAL DEVELOPMENT IN THE ARTS/Q1_A_D_PPDA_WK4_MOD4.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/ARTS AND DESIGN/PHYSICAL _ PERSONAL DEVELOPMENT IN THE ARTS/Q1_A_D_PPDA_WK5_MOD5.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/ARTS AND DESIGN/PHYSICAL _ PERSONAL DEVELOPMENT IN THE ARTS/Q1_A_D_PPDA_WK6_MOD6.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/ARTS AND DESIGN/PHYSICAL _ PERSONAL DEVELOPMENT IN THE ARTS/Q1_A_D_PPDA_WK7_MOD7.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/GAS/Humanities 1/Classworks/Classwork 3.docx",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/GAS/Humanities 1/Classworks/Classwork 4.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/GAS/Humanities 1/Classworks/Performance Task 2.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/GAS/Humanities 1/Lessons/Lesson 1.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/GAS/Humanities 1/Lessons/Lesson 2.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/GAS/Humanities 1/Lessons/Lesson 3.docx",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/GAS/Humanities 1/Lessons/Lesson 4.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/GAS/Humanities 1/Lessons/Lesson 5.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/GAS/Humanities 1/Lessons/Lesson 6.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/GAS/ORGMAN/ORGMAN Q1M1.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/GAS/ORGMAN/ORGMAN Q1M2.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/GAS/ORGMAN/ORGMAN Q1M3.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/GAS/ORGMAN/ORGMAN Q1M4.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/GAS/ORGMAN/ORGMAN Q1M5.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/HUMSS/Creative-Writing-LM.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/HUMSS/Trends Networks and Critical Thinking Module 1.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/HUMSS/Trends Networks and Critical Thinking Module 2.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/HUMSS/Trends Networks and Critical Thinking Module 3.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/HUMSS/Trends Networks and Critical Thinking Module 4.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/HUMSS/Trends Networks and Critical Thinking Module 5.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/HUMSS/Trends Networks and Critical Thinking Module 6.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/HUMSS/Trends Networks and Critical Thinking Module 7.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/HUMSS/Trends-Networks-and-Critical-Thinking-in-the-21st-Century.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/STEM/General Biology 1/GB1_Curriculum TImeline.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/STEM/General Biology 1/General Biology 1 Removal Acctivity.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/STEM/General Biology 1/General Biology 1 Removal Exam.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/STEM/General Biology 1/Quarter 1/GB1_Q1_Mod1.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/STEM/General Biology 1/Quarter 1/GB1_Q1_Mod2.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/STEM/General Biology 1/Quarter 1/GB1_Q1_Mod3.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/STEM/General Biology 1/Quarter 1/GB1_Q1_Mod4.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/STEM/General Biology 1/Quarter 1/GB1_Q1_Mod5.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/STEM/General Biology 1/Quarter 1/GB1_Q1_Mod6.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/STEM/General Biology 1/Quarter 2/GB1_Q2_Mod1.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/STEM/General Biology 1/Quarter 2/GB1_Q2_Mod2.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/STEM/General Biology 1/Quarter 2/GB1_Q2_Mod3.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/STEM/General Biology 1/Quarter 2/GB1_Q2_Mod4.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/STEM/General Biology 1/Removal Eam Answer Key.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/STEM/Pre - Calculus/Worksheets_Unit_1_Mode_1_Pre-Calculus.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/STEM/Pre - Calculus/Worksheets_Unit_1_Mode_2_Pre-Calculus.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/STEM/Pre - Calculus/Worksheets_Unit_1_Mode_3-4_Pre-Calculus.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/STEM/Pre - Calculus/Worksheets_Unit_1_Mode_3_Pre-Calculus.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/STEM/Pre - Calculus/Worksheets_Unit_1_Mode_5_Pre-Calculus.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/STEM/Pre - Calculus/Worksheets_Unit_1_Mode_6_Pre-Calculus.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/STEM/Pre - Calculus/Worksheets_Unit_1_Mode_7_Pre-Calculus.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/STEM/Pre - Calculus/Worksheets_Unit_1_Mode_8_Pre-Calculus.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/STEM/Pre - Calculus/Worksheets_Unit_2_Mode_10_Pre-Calculus.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/STEM/Pre - Calculus/Worksheets_Unit_2_Mode_11_Pre-Calculus.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/STEM/Pre - Calculus/Worksheets_Unit_2_Mode_12_Pre-Calculus.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/STEM/Pre - Calculus/Worksheets_Unit_2_Mode_13-14_Pre-Calculus.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/STEM/Pre - Calculus/Worksheets_Unit_2_Mode_15-16_Pre-Calculus.pdf",
  "assets/module/Senior High School/Grade 11/1st Semester/Major/STEM/Pre - Calculus/Worksheets_Unit_2_Mode_9_Pre-Calculus.pdf",
  "assets/module/Senior High School/Grade 11/2nd Semester/Core Subjects/Disaster Readiness and Risk Reduction/DRRR11_Q3_Mod1.pdf",
  "assets/module/Senior High School/Grade 11/2nd Semester/Core Subjects/Disaster Readiness and Risk Reduction/DRRR11_Q3_Mod2.pdf",
  "assets/module/Senior High School/Grade 11/2nd Semester/Core Subjects/Disaster Readiness and Risk Reduction/DRRR11_Q3_Mod3.pdf",
  "assets/module/Senior High School/Grade 11/2nd Semester/Core Subjects/Disaster Readiness and Risk Reduction/DRRR11_Q3_Mod4.pdf",
  "assets/module/Senior High School/Grade 11/2nd Semester/Core Subjects/Disaster Readiness and Risk Reduction/DRRR11_Q3_Mod5.pdf",
  "assets/module/Senior High School/Grade 11/2nd Semester/Core Subjects/Disaster Readiness and Risk Reduction/DRRR11_Q3_Mod6.pdf",
  "assets/module/Senior High School/Grade 11/2nd Semester/Core Subjects/Disaster Readiness and Risk Reduction/DRRR11_Q4_Mod1.pdf",
  "assets/module/Senior High School/Grade 11/2nd Semester/Core Subjects/ENTREPRENEURSHIP/ENTERPRENUERSHIP _Q1_MODULE 1 ISALA.pdf",
  "assets/module/Senior High School/Grade 11/2nd Semester/Core Subjects/ENTREPRENEURSHIP/ENTREPRENEURSHIP  Q1 MODULE 2 ISALA.pdf",
  "assets/module/Senior High School/Grade 11/2nd Semester/Core Subjects/ENTREPRENEURSHIP/ENTREPRENEURSHIP  Q1 MODULE 3 ISALA.pdf",
  "assets/module/Senior High School/Grade 11/2nd Semester/Core Subjects/ENTREPRENEURSHIP/ENTREPRENEURSHIP  Q1 MODULE 4 ISALA.pdf",
  "assets/module/Senior High School/Grade 11/2nd Semester/Core Subjects/ENTREPRENEURSHIP/ENTREPRENEURSHIP  Q1 MODULE 5 ISALA.pdf",
  "assets/module/Senior High School/Grade 11/2nd Semester/Core Subjects/ENTREPRENEURSHIP/ENTREPRENEURSHIP  Q1 MODULE 6 ISALA.pdf",
  "assets/module/Senior High School/Grade 11/2nd Semester/Core Subjects/ENTREPRENEURSHIP/ENTREPRENEURSHIP  Q1 MODULE 7 ISALA.pdf",
  "assets/module/Senior High School/Grade 11/2nd Semester/Core Subjects/ENTREPRENEURSHIP/ENTREPRENEURSHIP  Q1 MODULE 8 ISALA.pdf",
  "assets/module/Senior High School/Grade 11/2nd Semester/Core Subjects/Pagbasa at Pagsusuri ng Iba_t Ibang Teksto Tungo sa Pananaliksik/Quarter 3/PPITTP Q3 W1.pdf",
  "assets/module/Senior High School/Grade 11/2nd Semester/Core Subjects/Pagbasa at Pagsusuri ng Iba_t Ibang Teksto Tungo sa Pananaliksik/Quarter 3/PPITTP Q3 W2.pdf",
  "assets/module/Senior High School/Grade 11/2nd Semester/Core Subjects/Pagbasa at Pagsusuri ng Iba_t Ibang Teksto Tungo sa Pananaliksik/Quarter 3/PPITTP Q3 W3.pdf",
  "assets/module/Senior High School/Grade 11/2nd Semester/Core Subjects/Pagbasa at Pagsusuri ng Iba_t Ibang Teksto Tungo sa Pananaliksik/Quarter 3/PPITTP Q3 W4.pdf",
  "assets/module/Senior High School/Grade 11/2nd Semester/Core Subjects/Pagbasa at Pagsusuri ng Iba_t Ibang Teksto Tungo sa Pananaliksik/Quarter 3/PPITTP Q3 W5.pdf",
  "assets/module/Senior High School/Grade 11/2nd Semester/Core Subjects/Pagbasa at Pagsusuri ng Iba_t Ibang Teksto Tungo sa Pananaliksik/Quarter 3/PPITTP Q3 W6.pdf",
  "assets/module/Senior High School/Grade 11/2nd Semester/Core Subjects/Pagbasa at Pagsusuri ng Iba_t Ibang Teksto Tungo sa Pananaliksik/Quarter 3/PPITTP Q3 W7.pdf",
  "assets/module/Senior High School/Grade 11/2nd Semester/Core Subjects/Pagbasa at Pagsusuri ng Iba_t Ibang Teksto Tungo sa Pananaliksik/Quarter 4/PPITTP Q4 W1.pdf",
  "assets/module/Senior High School/Grade 11/2nd Semester/Core Subjects/Pagbasa at Pagsusuri ng Iba_t Ibang Teksto Tungo sa Pananaliksik/Quarter 4/PPITTP Q4 W2.pdf",
  "assets/module/Senior High School/Grade 11/2nd Semester/Core Subjects/Pagbasa at Pagsusuri ng Iba_t Ibang Teksto Tungo sa Pananaliksik/Quarter 4/PPITTP Q4 W3.pdf",
  "assets/module/Senior High School/Grade 11/2nd Semester/Core Subjects/Pagbasa at Pagsusuri ng Iba_t Ibang Teksto Tungo sa Pananaliksik/Quarter 4/PPITTP Q4 W4.pdf",
  "assets/module/Senior High School/Grade 11/2nd Semester/Core Subjects/Pagbasa at Pagsusuri ng Iba_t Ibang Teksto Tungo sa Pananaliksik/Quarter 4/PPITTP Q4 W5.pdf",
  "assets/module/Senior High School/Grade 11/2nd Semester/Core Subjects/Pagbasa at Pagsusuri ng Iba_t Ibang Teksto Tungo sa Pananaliksik/Quarter 4/PPITTP Q4 W6.pdf",
  "assets/module/Senior High School/Grade 11/2nd Semester/Core Subjects/PHYSICAL EDUCATION AND HEALTH 2 (H.O.P.E.2)/Finals Module/FINALS_PE AND HEALTH 2_MODULE_1_WEEK_1.pdf",
  "assets/module/Senior High School/Grade 11/2nd Semester/Core Subjects/PHYSICAL EDUCATION AND HEALTH 2 (H.O.P.E.2)/Finals Module/FINALS_PE AND HEALTH 2_MODULE_2_WEEK_2.pdf",
  "assets/module/Senior High School/Grade 11/2nd Semester/Core Subjects/PHYSICAL EDUCATION AND HEALTH 2 (H.O.P.E.2)/PE AND HEALTH 2_MODULE_1_WEEK_1.pdf",
  "assets/module/Senior High School/Grade 11/2nd Semester/Core Subjects/PHYSICAL EDUCATION AND HEALTH 2 (H.O.P.E.2)/PE AND HEALTH 2_MODULE_2_WEEK_2.pdf",
  "assets/module/Senior High School/Grade 11/2nd Semester/Core Subjects/PHYSICAL EDUCATION AND HEALTH 2 (H.O.P.E.2)/PE AND HEALTH 2_MODULE_3_WEEK_3.pdf",
  "assets/module/Senior High School/Grade 11/2nd Semester/Core Subjects/PHYSICAL EDUCATION AND HEALTH 2 (H.O.P.E.2)/PE AND HEALTH 2_MODULE_4_WEEK_4.pdf",
  "assets/module/Senior High School/Grade 11/2nd Semester/Core Subjects/PHYSICAL EDUCATION AND HEALTH 2 (H.O.P.E.2)/PE AND HEALTH 2_MODULE_5_WEEK_5.pdf",
  "assets/module/Senior High School/Grade 11/2nd Semester/Major Subjects/APPLIED ECONOMICS/ApEcon Module 1.pdf",
  "assets/module/Senior High School/Grade 11/2nd Semester/Major Subjects/APPLIED ECONOMICS/ApEcon Module 2.pdf",
  "assets/module/Senior High School/Grade 11/2nd Semester/Major Subjects/APPLIED ECONOMICS/ApEcon Module 3.pdf",
  "assets/module/Senior High School/Grade 11/2nd Semester/Major Subjects/APPLIED ECONOMICS/ApEcon Module 4.pdf",
  "assets/module/Senior High School/Grade 11/2nd Semester/Major Subjects/BUSINESS ETHICS/ABM-BUSINESS ETHICS _ SOCIAL RESPONSIBILITY 12_Q1_W1_Mod1-converted.pdf",
  "assets/module/Senior High School/Grade 11/2nd Semester/Major Subjects/BUSINESS ETHICS/ABM-BUSINESS ETHICS _ SOCIAL RESPONSIBILITY 12_Q1_W2_Mod2 (1).pdf",
  "assets/module/Senior High School/Grade 11/2nd Semester/Major Subjects/BUSINESS ETHICS/ABM-BUSINESS ETHICS _ SOCIAL RESPONSIBILITY 12_Q1_W3_Mod3 (1).pdf",
  "assets/module/Senior High School/Grade 11/2nd Semester/Major Subjects/BUSINESS ETHICS/ABM-BUSINESS ETHICS _ SOCIAL RESPONSIBILITY 12_Q1_W4_Mod4 (1).pdf",
  "assets/module/Senior High School/Grade 11/2nd Semester/Major Subjects/General Biology 2/GB2_Q3_Mod1.pdf",
  "assets/module/Senior High School/Grade 11/2nd Semester/Major Subjects/General Biology 2/GB2_Q3_Mod2.pdf",
  "assets/module/Senior High School/Grade 11/2nd Semester/Major Subjects/General Biology 2/GB2_Q3_Mod3.pdf",
  "assets/module/Senior High School/Grade 11/2nd Semester/Major Subjects/General Biology 2/GB2_Q3_Mod4.pdf",
  "assets/module/Senior High School/Grade 11/2nd Semester/Major Subjects/General Biology 2/GB2_Q3_Mod5.pdf",
  "assets/module/Senior High School/Grade 11/2nd Semester/Major Subjects/General Biology 2/GB2_Q3_Mod6.pdf",
  "assets/module/Senior High School/Grade 11/2nd Semester/Major Subjects/General Biology 2/GB2_Q4_Mod1.pdf",
  "assets/module/Senior High School/Grade 12/1st Semester/Core Subjects/ELECTIVE 1_ FOOD AND BEVERAGE SERVICES/Activity Sheets/Classwork 2.pdf",
  "assets/module/Senior High School/Grade 12/1st Semester/Core Subjects/ELECTIVE 1_ FOOD AND BEVERAGE SERVICES/Activity Sheets/Classwork 3.pdf",
  "assets/module/Senior High School/Grade 12/1st Semester/Core Subjects/ELECTIVE 1_ FOOD AND BEVERAGE SERVICES/Activity Sheets/Classwork 4.docx",
  "assets/module/Senior High School/Grade 12/1st Semester/Core Subjects/ELECTIVE 1_ FOOD AND BEVERAGE SERVICES/Activity Sheets/Classwork 5.pdf",
  "assets/module/Senior High School/Grade 12/1st Semester/Core Subjects/ELECTIVE 1_ FOOD AND BEVERAGE SERVICES/Activity Sheets/Classwork 6.pdf",
  "assets/module/Senior High School/Grade 12/1st Semester/Core Subjects/ELECTIVE 1_ FOOD AND BEVERAGE SERVICES/Lectures/Lesson 1.pdf",
  "assets/module/Senior High School/Grade 12/1st Semester/Core Subjects/ELECTIVE 1_ FOOD AND BEVERAGE SERVICES/Lectures/Lesson 2.pdf",
  "assets/module/Senior High School/Grade 12/1st Semester/Core Subjects/ELECTIVE 1_ FOOD AND BEVERAGE SERVICES/Lectures/Lesson 3.pdf",
  "assets/module/Senior High School/Grade 12/1st Semester/Core Subjects/ELECTIVE 1_ FOOD AND BEVERAGE SERVICES/Lectures/Lesson 4.pdf",
  "assets/module/Senior High School/Grade 12/1st Semester/Core Subjects/ELECTIVE 1_ FOOD AND BEVERAGE SERVICES/Lectures/Lesson 5.pdf",
  "assets/module/Senior High School/Grade 12/1st Semester/Core Subjects/ELECTIVE 1_ FOOD AND BEVERAGE SERVICES/Lectures/Lesson 6.pdf",
  "assets/module/Senior High School/Grade 12/1st Semester/Core Subjects/ELECTIVE 2_ FRONT OFFICE SERVICES/Lesson 1.pdf",
  "assets/module/Senior High School/Grade 12/1st Semester/Core Subjects/English for Academics and Professional Purposes/ACAD1.pdf",
  "assets/module/Senior High School/Grade 12/1st Semester/Core Subjects/English for Academics and Professional Purposes/ACAD2.pdf",
  "assets/module/Senior High School/Grade 12/1st Semester/Core Subjects/English for Academics and Professional Purposes/ACAD3.pdf",
  "assets/module/Senior High School/Grade 12/1st Semester/Core Subjects/English for Academics and Professional Purposes/ACAD4.pdf",
  "assets/module/Senior High School/Grade 12/1st Semester/Core Subjects/English for Academics and Professional Purposes/ACAD5.pdf",
  "assets/module/Senior High School/Grade 12/1st Semester/Core Subjects/English for Academics and Professional Purposes/ACAD6.pdf",
  "assets/module/Senior High School/Grade 12/1st Semester/Core Subjects/English for Academics and Professional Purposes/ACAD7.pdf",
  "assets/module/Senior High School/Grade 12/1st Semester/Core Subjects/English for Academics and Professional Purposes/ACAD8.pdf",
  "assets/module/Senior High School/Grade 12/1st Semester/Core Subjects/English for Academics and Professional Purposes/ACAD9.pdf",
  "assets/module/Senior High School/Grade 12/1st Semester/Core Subjects/ENTREPRENEURSHIP/ENTERPRENUERSHIP 12_Q1_MODULE 1 ISALA.pdf",
  "assets/module/Senior High School/Grade 12/1st Semester/Core Subjects/ENTREPRENEURSHIP/ENTREPRENEURSHIP 12 Q1 MODULE 2 ISALA.pdf",
  "assets/module/Senior High School/Grade 12/1st Semester/Core Subjects/ENTREPRENEURSHIP/ENTREPRENEURSHIP 12 Q1 MODULE 3 ISALA.pdf",
  "assets/module/Senior High School/Grade 12/1st Semester/Core Subjects/ENTREPRENEURSHIP/ENTREPRENEURSHIP 12 Q1 MODULE 4 ISALA.pdf",
  "assets/module/Senior High School/Grade 12/1st Semester/Core Subjects/ENTREPRENEURSHIP/ENTREPRENEURSHIP 12 Q1 MODULE 5 ISALA.pdf",
  "assets/module/Senior High School/Grade 12/1st Semester/Core Subjects/ENTREPRENEURSHIP/ENTREPRENEURSHIP 12 Q1 MODULE 6 ISALA.pdf",
  "assets/module/Senior High School/Grade 12/1st Semester/Core Subjects/PHYSICAL EDUCATION AND HEALTH 3 (H.O.P.E. 3)/PE AND HEALTH 3_MODULE_1_WEEK_1.pdf",
  "assets/module/Senior High School/Grade 12/1st Semester/Core Subjects/PHYSICAL EDUCATION AND HEALTH 3 (H.O.P.E. 3)/PE AND HEALTH 3_MODULE_2_WEEK_2.pdf",
  "assets/module/Senior High School/Grade 12/1st Semester/Core Subjects/PHYSICAL EDUCATION AND HEALTH 3 (H.O.P.E. 3)/PE AND HEALTH 3_MODULE_3_WEEK_3 PDF.pdf",
  "assets/module/Senior High School/Grade 12/1st Semester/Core Subjects/PHYSICAL EDUCATION AND HEALTH 3 (H.O.P.E. 3)/PE AND HEALTH 3_MODULE_4_WEEK_4 PDF.pdf",
  "assets/module/Senior High School/Grade 12/1st Semester/Core Subjects/PHYSICAL EDUCATION AND HEALTH 3 (H.O.P.E. 3)/PE AND HEALTH 3_MODULE_5_6_WEEK_5_6.pdf",
  "assets/module/Senior High School/Grade 12/1st Semester/Core Subjects/PHYSICAL EDUCATION AND HEALTH 3 (H.O.P.E. 3)/PE AND HEALTH 3_MODULE_7_WEEK_7.pdf",
  "assets/module/Senior High School/Grade 12/1st Semester/Core Subjects/PHYSICAL EDUCATION AND HEALTH 3 (H.O.P.E. 3)/PE AND HEALTH 3_MODULE_8_WEEK_8.pdf",
  "assets/module/Senior High School/Grade 12/1st Semester/Core Subjects/Understanding Culture Society and Politics/UCSP1.pdf",
  "assets/module/Senior High School/Grade 12/1st Semester/Core Subjects/Understanding Culture Society and Politics/UCSP2.pdf",
  "assets/module/Senior High School/Grade 12/1st Semester/Core Subjects/Understanding Culture Society and Politics/UCSP3.pdf",
  "assets/module/Senior High School/Grade 12/1st Semester/Core Subjects/Understanding Culture Society and Politics/UCSP4.pdf",
  "assets/module/Senior High School/Grade 12/1st Semester/Core Subjects/Understanding Culture Society and Politics/UCSP5.pdf",
  "assets/module/Senior High School/Grade 12/1st Semester/Major/ABM/BUSINESS FINANCE/BUSFIN-isalaABM-M1.pdf",
  "assets/module/Senior High School/Grade 12/1st Semester/Major/ABM/BUSINESS FINANCE/BUSFIN-isalaABM-M2.pdf",
  "assets/module/Senior High School/Grade 12/1st Semester/Major/ABM/BUSINESS FINANCE/BUSFIN-isalaABM-M3.pdf",
  "assets/module/Senior High School/Grade 12/1st Semester/Major/ABM/BUSINESS FINANCE/BUSFIN-isalaABM-M4.pdf",
  "assets/module/Senior High School/Grade 12/1st Semester/Major/ABM/BUSINESS FINANCE/BUSFIN-isalaABM-M5.pdf",
  "assets/module/Senior High School/Grade 12/1st Semester/Major/ABM/FUNDAMENTALS TO ABM 2/FABM 2 (1-2)ISALA.pdf",
  "assets/module/Senior High School/Grade 12/1st Semester/Major/ABM/FUNDAMENTALS TO ABM 2/FABM 2-3 ISALA.pdf",
  "assets/module/Senior High School/Grade 12/1st Semester/Major/ABM/FUNDAMENTALS TO ABM 2/FABM 2-4-ISALA.pdf",
  "assets/module/Senior High School/Grade 12/1st Semester/Major/GAS/HOUSEKEEPING SERVICES/Lessons/Lesson 1.pdf",
  "assets/module/Senior High School/Grade 12/1st Semester/Major/GAS/HOUSEKEEPING SERVICES/Lessons/Lesson 2.pdf",
  "assets/module/Senior High School/Grade 12/1st Semester/Major/GAS/HOUSEKEEPING SERVICES/Lessons/Lesson 3.pdf",
  "assets/module/Senior High School/Grade 12/1st Semester/Major/GAS/HOUSEKEEPING SERVICES/Worksheets/Classwork 2.docx",
  "assets/module/Senior High School/Grade 12/1st Semester/Major/GAS/HOUSEKEEPING SERVICES/Worksheets/Classwork 3.docx",
  "assets/module/Senior High School/Grade 12/1st Semester/Major/GAS/HOUSEKEEPING SERVICES/Worksheets/Quiz 1.pdf",
  "assets/module/Senior High School/Grade 12/1st Semester/Major/GAS/SOCIAL SCIENCE 1/Lessons/Lesson 1.pdf",
  "assets/module/Senior High School/Grade 12/1st Semester/Major/GAS/SOCIAL SCIENCE 1/Lessons/Lesson 2.pdf",
  "assets/module/Senior High School/Grade 12/1st Semester/Major/GAS/SOCIAL SCIENCE 1/Lessons/Lesson 3.pdf",
  "assets/module/Senior High School/Grade 12/1st Semester/Major/GAS/SOCIAL SCIENCE 1/Lessons/Lesson 4.pdf",
  "assets/module/Senior High School/Grade 12/1st Semester/Major/GAS/SOCIAL SCIENCE 1/Lessons/Lesson 5.pdf",
  "assets/module/Senior High School/Grade 12/1st Semester/Major/GAS/SOCIAL SCIENCE 1/Lessons/Lesson 6.pdf",
  "assets/module/Senior High School/Grade 12/1st Semester/Major/GAS/SOCIAL SCIENCE 1/Lessons/Lesson 7.pdf",
  "assets/module/Senior High School/Grade 12/1st Semester/Major/GAS/SOCIAL SCIENCE 1/Worksheets/Worksheet 3.docx.pdf",
  "assets/module/Senior High School/Grade 12/1st Semester/Major/GAS/SOCIAL SCIENCE 1/Worksheets/Worksheet 4.pdf",
  "assets/module/Senior High School/Grade 12/1st Semester/Major/GAS/SOCIAL SCIENCE 1/Worksheets/Worksheet 5.docx",
  "assets/module/Senior High School/Grade 12/1st Semester/Major/GAS/SOCIAL SCIENCE 1/Worksheets/Worksheet 6.pdf",
  "assets/module/Senior High School/Grade 12/1st Semester/Major/GAS/SOCIAL SCIENCE 1/Worksheets/Worksheet 7.pdf",
  "assets/module/Senior High School/Grade 12/1st Semester/Major/HUMSS/Com Involvement.pdf",
  "assets/module/Senior High School/Grade 12/1st Semester/Major/HUMSS/Community Involvement Lesson.pptx",
  "assets/module/Senior High School/Grade 12/1st Semester/Major/HUMSS/Introduction to Social Science/Introduction to Social Science (1).pdf",
  "assets/module/Senior High School/Grade 12/1st Semester/Major/HUMSS/Introduction to Social Science/Introduction to Social Science (2).pdf",
  "assets/module/Senior High School/Grade 12/1st Semester/Major/HUMSS/Introduction to Social Science/Introduction to Social Science (3).pdf",
  "assets/module/Senior High School/Grade 12/2nd Semester/Core Subjects/CONTEMPORARY PHILIPPINE ARTS FROM THE REGIONS/ContemporaryArts12_Mod1 and 2_Contemporary_Arts_Forms_ver3.pdf",
  "assets/module/Senior High School/Grade 12/2nd Semester/Core Subjects/CONTEMPORARY PHILIPPINE ARTS FROM THE REGIONS/ContemporaryArts12_Mod3_Contemporary_Arts_Forms_ver3.pdf",
  "assets/module/Senior High School/Grade 12/2nd Semester/Core Subjects/CONTEMPORARY PHILIPPINE ARTS FROM THE REGIONS/ContemporaryArts12_Q1_Mod4_Contemporary_Arts_Forms_ver3.pdf",
  "assets/module/Senior High School/Grade 12/2nd Semester/Core Subjects/CONTEMPORARY PHILIPPINE ARTS FROM THE REGIONS/ContemporaryArts12_Q1_Mod5_Contemporary_Arts_Forms_ver3.pdf",
  "assets/module/Senior High School/Grade 12/2nd Semester/Core Subjects/CONTEMPORARY PHILIPPINE ARTS FROM THE REGIONS/ContemporaryArts12_Q1_Mod6_Contemporary_Arts_Forms_ver3.pdf",
  "assets/module/Senior High School/Grade 12/2nd Semester/Core Subjects/CONTEMPORARY PHILIPPINE ARTS FROM THE REGIONS/ContemporaryArts12_Q1_Mod7_Contemporary_Arts_Forms.pdf",
  "assets/module/Senior High School/Grade 12/2nd Semester/Core Subjects/PHYSICAL EDUCATION AND HEALTH 4 (H.O.P.E.4)/Final Module/FINALS_PE AND HEALTH 4_MODULE_1_WEEK_1.pdf",
  "assets/module/Senior High School/Grade 12/2nd Semester/Core Subjects/PHYSICAL EDUCATION AND HEALTH 4 (H.O.P.E.4)/Final Module/FINALS_PE AND HEALTH 4_MODULE_2_WEEK_2.pdf",
  "assets/module/Senior High School/Grade 12/2nd Semester/Core Subjects/PHYSICAL EDUCATION AND HEALTH 4 (H.O.P.E.4)/PE AND HEALTH 4_MODULE_1_WEEK_1.pdf",
  "assets/module/Senior High School/Grade 12/2nd Semester/Core Subjects/PHYSICAL EDUCATION AND HEALTH 4 (H.O.P.E.4)/PE AND HEALTH 4_MODULE_2_WEEK_2.pdf",
  "assets/module/Senior High School/Grade 12/2nd Semester/Core Subjects/PHYSICAL EDUCATION AND HEALTH 4 (H.O.P.E.4)/PE AND HEALTH 4_MODULE_3_WEEK_3.pdf",
  "assets/module/Senior High School/Grade 12/2nd Semester/Core Subjects/PHYSICAL EDUCATION AND HEALTH 4 (H.O.P.E.4)/PE AND HEALTH 4_MODULE_4_WEEK_4.pdf",
  "assets/module/Senior High School/Grade 12/2nd Semester/Major Subjects/ABM/PRINCIPLES OF MARKETING/POM MODULE 1.pdf",
  "assets/module/Senior High School/Grade 12/2nd Semester/Major Subjects/ABM/PRINCIPLES OF MARKETING/POM MODULE 2.pdf",
  "assets/module/Senior High School/Grade 12/2nd Semester/Major Subjects/ABM/PRINCIPLES OF MARKETING/POM MODULE 3.pdf",
  "assets/module/Senior High School/Grade 12/2nd Semester/Major Subjects/ABM/PRINCIPLES OF MARKETING/POM MODULE 4.pdf",
  "assets/module/Senior High School/Grade 12/2nd Semester/Major Subjects/GAS/Disaster Readiness and Risk Reduction/DRRR12_Q3_Mod1.pdf",
  "assets/module/Senior High School/Grade 12/2nd Semester/Major Subjects/GAS/Disaster Readiness and Risk Reduction/DRRR12_Q3_Mod2.pdf",
  "assets/module/Senior High School/Grade 12/2nd Semester/Major Subjects/GAS/Disaster Readiness and Risk Reduction/DRRR12_Q3_Mod3.pdf",
  "assets/module/Senior High School/Grade 12/2nd Semester/Major Subjects/GAS/Disaster Readiness and Risk Reduction/DRRR12_Q3_Mod4.pdf",
  "assets/module/Senior High School/Grade 12/2nd Semester/Major Subjects/GAS/Disaster Readiness and Risk Reduction/DRRR12_Q3_Mod5.pdf",
  "assets/module/Senior High School/Grade 12/2nd Semester/Major Subjects/GAS/Disaster Readiness and Risk Reduction/DRRR12_Q3_Mod6.pdf",
  "assets/module/Senior High School/Grade 12/2nd Semester/Major Subjects/GAS/Disaster Readiness and Risk Reduction/DRRR12_Q4_Mod1.pdf",
  "assets/module/Senior High School/Grade 12/2nd Semester/Major Subjects/STEM/General Chemistry/GC2_Q3_Mod1.pdf",
  "assets/module/Senior High School/Grade 12/2nd Semester/Major Subjects/STEM/General Chemistry/GC2_Q3_Mod2.pdf",
  "assets/module/Senior High School/Grade 12/2nd Semester/Major Subjects/STEM/General Chemistry/GC2_Q3_Mod3.pdf",
  "assets/module/Senior High School/Grade 12/2nd Semester/Major Subjects/STEM/General Chemistry/GC2_Q3_Mod4.pdf",
  "assets/module/Senior High School/Grade 12/2nd Semester/Major Subjects/STEM/General Chemistry/GC2_Q3_Mod5.pdf",
  "assets/module/Senior High School/Grade 12/2nd Semester/Major Subjects/STEM/General Chemistry/GC2_Q3_Mod6.pdf",
  "assets/module/Senior High School/Grade 12/2nd Semester/Major Subjects/STEM/General Chemistry/GC2_Q4.pdf",
  "assets/module/Senior High School/Progress Report (SHS).pub",
  "assets/module/Senior High School/SHS-Checklist.pdf"
]
export const LiveUrl = "https://isala.com.ph/";
export const DevUrl = "https://isala.com.ph/"; //http://localhost:3000/  http://localhost:3000/
